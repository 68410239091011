define("@fantasy-account/webapp/templates/components/list-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pjuoAmRD",
    "block": "{\"symbols\":[\"column\",\"index\",\"&default\"],\"statements\":[[6,[37,7],[[27,[32,3]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,3,[[30,[36,6],null,[[\"header\"],[[30,[36,5],[\"list-header-column\"],[[\"onClick\"],[[30,[36,0],[[32,0],\"onClickColumn\"],null]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],null,[[\"index\",\"slug\",\"label\",\"sortable\",\"order\",\"name\",\"onClick\"],[[32,2],[32,1,[\"id\"]],[32,1,[\"label\"]],[32,1,[\"sortable\"]],[32,1,[\"order\"]],[32,1,[\"name\"]],[30,[36,0],[[32,0],\"onClickColumn\"],null]]]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"list-header-column\",\"columns\",\"-track-array\",\"each\",\"component\",\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/list-header.hbs"
    }
  });

  _exports.default = _default;
});