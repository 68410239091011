define("@fantasy-account/webapp/data/serializers/index", ["exports", "@playon/framework/data/serializers"], function (_exports, _serializers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "BasicSerializer", {
    enumerable: true,
    get: function () {
      return _serializers.BasicSerializer;
    }
  });
  Object.defineProperty(_exports, "HasCaptcha", {
    enumerable: true,
    get: function () {
      return _serializers.HasCaptcha;
    }
  });
  Object.defineProperty(_exports, "HasCountry", {
    enumerable: true,
    get: function () {
      return _serializers.HasCountry;
    }
  });
  Object.defineProperty(_exports, "HasCurrency", {
    enumerable: true,
    get: function () {
      return _serializers.HasCurrency;
    }
  });
  Object.defineProperty(_exports, "HasIncludedModels", {
    enumerable: true,
    get: function () {
      return _serializers.HasIncludedModels;
    }
  });
  Object.defineProperty(_exports, "Verifiable", {
    enumerable: true,
    get: function () {
      return _serializers.Verifiable;
    }
  });
});