define("@fantasy-account/webapp/templates/components/child", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nP16T/cm",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,2],[\"child-content\"],[[\"layoutName\",\"context\"],[[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"parent\",\"templateFullPath\",\"component\",\"template\",\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/child.hbs"
    }
  });

  _exports.default = _default;
});