define("@fantasy-account/webapp/modifiers/autofocus", ["exports", "@playon/framework/modifiers/autofocus"], function (_exports, _autofocus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _autofocus.default;
    }
  });
});