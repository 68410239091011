define("@fantasy-account/webapp/templates/components/tab-nav-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0jHeF5gT",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,0],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,2],[[35,1]],[[\"class\"],[\"tabs__item__icon\"]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,0,[\"label\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"tabs__item__label\"],[12],[2,\"\\n    \"],[1,[32,0,[\"label\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"tabs__item__label\"],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"icon\",\"icon-view\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/tab-nav-item.hbs"
    }
  });

  _exports.default = _default;
});