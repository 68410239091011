define("@fantasy-account/webapp/decorator/date/index", ["exports", "@playon/framework/decorator/date"], function (_exports, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "isFuture", {
    enumerable: true,
    get: function () {
      return _date.isFuture;
    }
  });
  Object.defineProperty(_exports, "isPast", {
    enumerable: true,
    get: function () {
      return _date.isPast;
    }
  });
});