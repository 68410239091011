define("@fantasy-account/webapp/templates/components/list-rows/text-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F82jSssU",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,5],[[35,4],[35,3],[35,2]],[[\"placeholder\",\"nestedClasses\",\"style\",\"modifier\"],[[35,1],\"list__row__text-field\",[32,0,[\"style\"]],[35,0]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"textFieldModifier\",\"placeholder\",\"value\",\"label\",\"name\",\"text-field\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/list-rows/text-field.hbs"
    }
  });

  _exports.default = _default;
});