define("@fantasy-account/webapp/templates/components/snackbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g0nt4Mzv",
    "block": "{\"symbols\":[\"&default\",\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[32,0,[\"classes\"]]],[17,2],[4,[38,3],[[32,0,[\"didInsert\"]]],null],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"snackbar__container\"],[12],[2,\"\\n\"],[6,[37,4],[[32,0,[\"closeButton\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"icon-button\",[[24,0,\"snackbar__close-button\"]],[[\"@icon\",\"@modifier\",\"@action\"],[\"times:fa:far\",\"xs\",[30,[36,0],[[32,0],[32,0,[\"reject\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,4],[[32,0,[\"branded\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"snackbar__brand\"],[12],[2,\"\\n        \"],[18,1,[[30,[36,2],null,[[\"Logo\"],[[30,[36,1],[\"logo\"],[[\"nestedClasses\"],[\"snackbar__brand__logo\"]]]]]]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"div\"],[14,0,\"snackbar__message\"],[12],[2,\"\\n\"],[6,[37,5],[[32,0,[\"message\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[32,0,[\"message\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"snackbar__actions\"],[12],[2,\"\\n\"],[6,[37,4],[[32,0,[\"actionLabel\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"text-button\",[[24,0,\"snackbar__action\"]],[[\"@label\",\"@action\"],[[32,0,[\"actionLabel\"]],[30,[36,0],[[32,0],[32,0,[\"resolve\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"component\",\"hash\",\"did-insert\",\"if\",\"unless\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/snackbar.hbs"
    }
  });

  _exports.default = _default;
});