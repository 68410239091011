define("@fantasy-account/webapp/undo/index", ["exports", "@playon/framework/undo"], function (_exports, _undo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "UndoCommand", {
    enumerable: true,
    get: function () {
      return _undo.UndoCommand;
    }
  });
  Object.defineProperty(_exports, "UndoStack", {
    enumerable: true,
    get: function () {
      return _undo.UndoStack;
    }
  });
});