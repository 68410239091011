define("@fantasy-account/webapp/templates/components/verification/document/picker/address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dPsZyzxF",
    "block": "{\"symbols\":[\"Control\",\"type\",\"@selectedDocumentType\",\"@selectedDocumentTypeName\",\"&attrs\",\"@isUploadMode\"],\"statements\":[[11,\"div\"],[16,0,[32,0,[\"className\"]]],[17,5],[12],[2,\"\\n\"],[6,[37,3],[[32,6]],[[\"use\"],[[32,0,[\"transition\"]]]],[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[[32,0,[\"className\"]],\"__message\"]]],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"verification.document.description\"],[[\"documentName\"],[[32,4]]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"select-control\",[],[[\"@theme\",\"@heading\",\"@label\",\"@selected\",\"@block\",\"@modifier\",\"@onChange\"],[\"fantasy-account\",[30,[36,0],[\"verification.document.label.type-of-document\"],null],[32,0,[\"documentTypeSelectControlLabel\"]],[32,3],true,\"md block\",[32,0,[\"changeDocumentType\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"documentTypes\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,[32,1,[\"Option\"]],[],[[\"@value\",\"@label\"],[[32,2],[30,[36,0],[[32,2,[\"name\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"-track-array\",\"each\",\"animated-if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/verification/document/picker/address.hbs"
    }
  });

  _exports.default = _default;
});