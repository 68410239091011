define("@fantasy-account/webapp/templates/components/text-field/date-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BZZ2DFAY",
    "block": "{\"symbols\":[\"@textField\"],\"statements\":[[10,\"input\"],[14,0,\"text-field__input\"],[15,1,[31,[[32,1,[\"inputId\"]],\"-text-field\"]]],[15,2,[32,1,[\"mask\",\"value\"]]],[15,3,[32,1,[\"name\"]]],[15,\"disabled\",[32,1,[\"disabled\"]]],[15,\"autocomplete\",[32,1,[\"autocomplete\"]]],[15,4,[32,1,[\"type\"]]],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/text-field/date-input.hbs"
    }
  });

  _exports.default = _default;
});