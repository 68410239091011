define("@fantasy-account/webapp/initializers/navigation-stack", ["exports", "@playon/framework/initializers/navigation-stack"], function (_exports, _navigationStack) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _navigationStack.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function () {
      return _navigationStack.initialize;
    }
  });
});