define("@fantasy-account/webapp/templates/components/list-rows/stepper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wtgjt8yV",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,9],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,[[30,[36,8],null,[[\"stepper\"],[[30,[36,7],[\"stepper-control\"],[[\"value\",\"label\",\"name\",\"min\",\"max\",\"stepValue\",\"nestedClasses\",\"modifier\"],[[35,5],[32,0,[\"label\"]],[35,4],[35,3],[35,2],[35,1],\"list__row__stepper\",[35,0]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,6],[[35,5]],[[\"label\",\"name\",\"min\",\"max\",\"stepValue\",\"nestedClasses\",\"modifier\"],[[32,0,[\"label\"]],[35,4],[35,3],[35,2],[35,1],\"list__row__stepper\",[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"stepperModifier\",\"stepValue\",\"max\",\"min\",\"name\",\"value\",\"stepper-control\",\"component\",\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/list-rows/stepper.hbs"
    }
  });

  _exports.default = _default;
});