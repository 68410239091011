define("@fantasy-account/webapp/templates/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PPNjdoh+",
    "block": "{\"symbols\":[\"DialogView\"],\"statements\":[[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\\n\"],[8,\"dialog\",[],[[\"@name\",\"@branded\",\"@theme\",\"@animation\"],[\"fantasy-account\",true,\"fantasy-account\",\"slide-up\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Logo\"]],[],[[\"@kind\",\"@name\",\"@alt\"],[\"fantasy-account\",\"logotype\",\"Fantasy Account Logotype\"]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/account.hbs"
    }
  });

  _exports.default = _default;
});