define("@fantasy-account/webapp/templates/components/select-control", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1ZDhSdn4",
    "block": "{\"symbols\":[\"Menu\",\"&attrs\",\"@buttonTheme\",\"@grouped\",\"@onChange\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[32,0,[\"classes\"]]],[17,2],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"heading\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"label\"],[15,0,[31,[[32,0,[\"className\"]],\"__heading\"]]],[12],[2,\"\\n      \"],[1,[32,0,[\"heading\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"text-button\",[],[[\"@menu\",\"@kind\",\"@theme\",\"@modifier\",\"@icon\",\"@iconPosition\"],[[32,0,[\"name\"]],[32,0,[\"kind\"]],[32,3],[32,0,[\"modifier\"]],[32,0,[\"icon\"]],\"right\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,0,[\"label\"]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,\"menu-view\",[],[[\"@theme\",\"@name\",\"@grouped\",\"@selected\",\"@block\",\"@fixed\",\"@onChange\",\"@placement\"],[[32,0,[\"theme\"]],[32,0,[\"name\"]],[32,4],[32,0,[\"selected\"]],[32,0,[\"block\"]],[32,0,[\"fixed\"]],[32,5],[32,0,[\"menuPlacement\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,6,[[30,[36,1],null,[[\"Item\",\"Option\",\"Separator\"],[[32,1,[\"Option\"]],[32,1,[\"Option\"]],[32,1,[\"Separator\"]]]]]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"hash\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/select-control.hbs"
    }
  });

  _exports.default = _default;
});