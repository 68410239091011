define("@fantasy-account/webapp/templates/components/filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KVZOv8Vw",
    "block": "{\"symbols\":[\"filter\",\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[32,0,[\"className\"]]],[17,2],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"filters__list\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"filters\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,1,[\"hidden\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],[[32,1,[\"component\"]]],[[\"filter\"],[[32,1]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\\n  \"],[10,\"footer\"],[14,0,\"filters__footer\"],[12],[2,\"\\n    \"],[8,\"text-button\",[],[[\"@enabled\",\"@theme\",\"@modifier\",\"@action\"],[[32,0,[\"filters\",\"active\"]],\"secondary\",\"flat half-block sm uppercase\",[32,0,[\"reset\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      Reset Filters\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"contained-button\",[],[[\"@theme\",\"@modifier\",\"@action\"],[\"secondary\",\"flat half-block sm uppercase\",[32,0,[\"args\",\"onApply\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,0,[\"applyButtonLabel\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"unless\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/filters.hbs"
    }
  });

  _exports.default = _default;
});