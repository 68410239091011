define("@fantasy-account/webapp/templates/components/app-bar/top/account/settings/change-language", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ip3lBp05",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],[[30,[36,0],[[32,0,[\"app-bar\",\"back-item\"]],\"expected `this.app-bar.back-item` to be a contextual component but found a string. Did you mean `(component this.app-bar.back-item)`? ('@fantasy-account/webapp/templates/components/app-bar/top/account/settings/change-language.hbs' @ L1:C2) \"],null]],[[\"route\",\"modifier\",\"theme\"],[\"account.settings.index\",\"size-24\",\"muted\"]]]],[2,\"\\n\"],[1,[30,[36,1],[[30,[36,0],[[32,0,[\"app-bar\",\"heading\"]],\"expected `this.app-bar.heading` to be a contextual component but found a string. Did you mean `(component this.app-bar.heading)`? ('@fantasy-account/webapp/templates/components/app-bar/top/account/settings/change-language.hbs' @ L2:C2) \"],null]],[[\"title\"],[[30,[36,2],[\"navigation.heading.change-language\"],null]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-assert-implicit-component-helper-argument\",\"component\",\"t\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/app-bar/top/account/settings/change-language.hbs"
    }
  });

  _exports.default = _default;
});