define("@fantasy-account/webapp/templates/components/application/closed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PAnlJ0g6",
    "block": "{\"symbols\":[],\"statements\":[[8,\"app/container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"header\"],[14,0,\"app-bar app-bar--theme-fantasy-account app-bar--bordered\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"app-bar__container\"],[12],[2,\"\\n      \"],[8,\"logo\",[[24,0,\"app-bar__brand\"]],[[\"@kind\",\"@alt\",\"@modifier\"],[\"fantasy-account\",\"Fantasy Account Logo\",\"centered\"]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"main\"],[14,0,\"account-closed\"],[12],[2,\"\\n    \"],[8,\"account/status-panel\",[],[[\"@title\"],[[30,[36,0],[\"account.status.suspended.title\"],null]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/application/closed.hbs"
    }
  });

  _exports.default = _default;
});