define("@fantasy-account/webapp/templates/components/list-row-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7rgrA0Yk",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"strong\"],[14,0,\"list__row__label__primary\"],[12],[1,[32,0,[\"label\"]]],[13],[2,\"\\n\\n\"],[6,[37,2],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"list__row__label__secondary\"],[12],[2,\"\\n    \"],[18,1,[[30,[36,5],null,[[\"badge\",\"icon\",\"label\"],[[30,[36,4],[\"badge\"],[[\"class\"],[\"list__row__label__secondary__item list__row__label__secondary__item--badge\"]]],[30,[36,4],[\"icon-view\"],[[\"class\"],[\"list__row__label__secondary__item list__row__label__secondary__item--icon\"]]],[30,[36,4],[\"label-view\"],[[\"class\"],[\"list__row__label__secondary__item list__row__label__secondary__item--label\"]]]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"small\"],[14,0,\"list__row__label__secondary\"],[12],[2,\"\\n\"],[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,1],[[35,0]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[1,[34,3]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"secondaryIcon\",\"icon-view\",\"if\",\"secondaryLabel\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/list-row-label.hbs"
    }
  });

  _exports.default = _default;
});