define("@fantasy-account/webapp/templates/account/settings/payment-methods/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6E1Enx2L",
    "block": "{\"symbols\":[],\"statements\":[[8,\"account/settings/payment-methods/method\",[],[[\"@isEditingMethodDetails\"],[true]],null],[2,\"\\n\"],[8,\"account/settings/payment-methods/form\",[],[[\"@isEditing\"],[true]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/account/settings/payment-methods/edit.hbs"
    }
  });

  _exports.default = _default;
});