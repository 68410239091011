define("@fantasy-account/webapp/templates/components/verification/status-stepper/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zRWxPB0g",
    "block": "{\"symbols\":[\"@onEdit\",\"@value\",\"@classes\",\"&attrs\",\"@icon\",\"@label\"],\"statements\":[[10,\"li\"],[14,0,\"verification-status-stepper__item verification-status-stepper__item--spacer\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"verification-status-stepper__item__ribbon verification-status-stepper__item__ribbon--middle\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[11,\"li\"],[16,0,[32,3]],[17,4],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"verification-status-stepper__item__ribbon verification-status-stepper__item__ribbon--top\"],[12],[13],[2,\"\\n  \"],[10,\"span\"],[14,0,\"verification-status-stepper__item__ribbon verification-status-stepper__item__ribbon--bottom\"],[12],[13],[2,\"\\n\\n  \"],[8,\"icon\",[[24,0,\"verification-status-stepper__item__icon\"]],[[\"@icon\"],[[32,5]]],null],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"verification-status-stepper__item__wrapper\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"verification-status-stepper__item__label\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[[32,6]],null]],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"verification-status-stepper__item__value\"],[12],[2,\"\\n        \"],[1,[32,2]],[2,\"\\n      \"],[13],[2,\"\\n\\n\"],[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"text-button\",[[24,0,\"verification-status-stepper__item__edit-button\"]],[[\"@modifier\",\"@action\"],[\"xs uppercase\",[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n          Edit\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"t\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/verification/status-stepper/item.hbs"
    }
  });

  _exports.default = _default;
});