define("@fantasy-account/webapp/templates/opt-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5Nf+CZ2K",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"opt-in\"],[12],[2,\"\\n  \"],[8,\"logo\",[[24,0,\"opt-in__link__logo\"],[4,[38,0],[\"click\",[32,0,[\"goToIndex\"]]],null]],[[\"@name\",\"@kind\",\"@alt\",\"@modifier\"],[\"abbreviation\",[32,0,[\"playon\",\"name\"]],\"Fantasy Logo\",\"centered\"]],null],[2,\"\\n\\n  \"],[10,\"header\"],[14,0,\"opt-in__header\"],[12],[2,\"\\n    \"],[10,\"p\"],[12],[1,[30,[36,1],[\"auth.opt-in.promo\"],[[\"sport\",\"htmlSafe\"],[[32,0,[\"playon\",\"name\"]],true]]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"opt-in/form\",[],[[],[]],null],[2,\"\\n\\n  \"],[10,\"small\"],[14,0,\"opt-in__detail text--sm\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"auth.opt-in.detail\"],[[\"sport\",\"htmlSafe\"],[[32,0,[\"playon\",\"name\"]],true]]]],[2,\" \"],[8,\"text-anchor\",[],[[\"@theme\",\"@label\",\"@href\",\"@target\",\"@modifier\"],[\"fantasy-account\",[30,[36,1],[\"auth.opt-in.privacy-policy\"],null],\"http://www.nba.com/news/privacy_policy.html\",\"privacy\",\"sm inline\"]],null],[2,\".\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"footer\"],[14,0,\"opt-in__footer text--sm text--muted\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"auth.copyright\"],[[\"year\"],[[30,[36,2],null,null]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"t\",\"current-year\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/opt-in.hbs"
    }
  });

  _exports.default = _default;
});