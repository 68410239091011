define("@fantasy-account/webapp/application/initializers/data-samples", ["exports", "@fantasy-account/engine/application/initializers/data-samples"], function (_exports, _dataSamples) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _dataSamples.default;
    }
  });
});