define("@fantasy-account/webapp/templates/account/settings/payment-methods/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zimc8GVq",
    "block": "{\"symbols\":[],\"statements\":[[8,\"account/settings/payment-methods/form\",[],[[],[]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/account/settings/payment-methods/new.hbs"
    }
  });

  _exports.default = _default;
});