define("@fantasy-account/webapp/templates/components/verification/status-stepper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G4GCCVZ4",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"ul\"],[16,0,[32,0,[\"className\"]]],[17,1],[12],[2,\"\\n  \"],[18,2,[[30,[36,1],null,[[\"Address\",\"Email\",\"Identity\"],[[30,[36,0],[\"verification/status-stepper/address\"],[[\"user\"],[[32,0,[\"user\"]]]]],[30,[36,0],[\"verification/status-stepper/email\"],[[\"user\"],[[32,0,[\"user\"]]]]],[30,[36,0],[\"verification/status-stepper/identity\"],[[\"user\"],[[32,0,[\"user\"]]]]]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/verification/status-stepper.hbs"
    }
  });

  _exports.default = _default;
});