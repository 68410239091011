define("@fantasy-account/webapp/templates/components/logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "98rCB2Ti",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[32,0,[\"classes\"]]],[17,1],[12],[2,\"\\n\"],[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,2],[[35,1]],[[\"class\"],[\"logo__image logo__image--svg\"]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"image-view\",[[24,0,\"logo__image logo__image--img\"]],[[\"@alt\",\"@src\"],[[34,0],[34,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"alt\",\"src\",\"inline-svg\",\"isSVG\",\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/logo.hbs"
    }
  });

  _exports.default = _default;
});