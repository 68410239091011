define("@fantasy-account/webapp/templates/components/camera/huds/selfie", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y5hRTeJ5",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"camera__hud__scanner\"],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[34,4]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,5],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"camera__hud__title\"],[12],[1,[34,3]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,5],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"camera__hud__message\"],[12],[1,[34,2]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"camera__hud__controls\"],[12],[2,\"\\n\"],[6,[37,7],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],null,[[\"class\",\"action\"],[\"camera__hud__controls__capture-button\",[30,[36,0],[[32,0],\"takePhoto\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"contained-button\",\"message\",\"title\",\"contentClassName\",\"if\",\"disabled\",\"unless\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/camera/huds/selfie.hbs"
    }
  });

  _exports.default = _default;
});