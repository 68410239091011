define("@fantasy-account/webapp/templates/components/camera", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X9leAT+R",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,5],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,[[30,[36,4],null,[[\"Hud\",\"ScanDocument\",\"Selfie\"],[[30,[36,3],[[35,2]],[[\"disabled\",\"camera\",\"onTakePhoto\"],[[35,1],[32,0],[30,[36,0],[[32,0],\"hudDidTakePhoto\"],null]]]],[30,[36,3],[\"camera/huds/scan-document\"],[[\"disabled\",\"camera\",\"onTakePhoto\"],[[35,1],[32,0],[30,[36,0],[[32,0],\"hudDidTakePhoto\"],null]]]],[30,[36,3],[\"camera/huds/selfie\"],[[\"disabled\",\"camera\",\"onTakePhoto\"],[[35,1],[32,0],[30,[36,0],[[32,0],\"hudDidTakePhoto\"],null]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,3],[[35,2]],[[\"disabled\",\"camera\",\"onTakePhoto\"],[[35,1],[32,0],[30,[36,0],[[32,0],\"hudDidTakePhoto\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"canvas\"],[14,0,\"camera__canvas\"],[12],[13],[2,\"\\n\"],[10,\"video\"],[14,\"autoplay\",\"\"],[14,\"playsinline\",\"\"],[14,0,\"camera__view\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"disabled\",\"hudComponent\",\"component\",\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/camera.hbs"
    }
  });

  _exports.default = _default;
});