define("@fantasy-account/webapp/templates/components/modal/aside", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "izLx8jdD",
    "block": "{\"symbols\":[\"@modal\",\"@onBack\",\"&attrs\",\"&default\"],\"statements\":[[11,\"aside\"],[16,0,[31,[[32,0,[\"classes\"]],\" \",[32,1],\"__aside\"]]],[17,3],[12],[2,\"\\n  \"],[10,\"header\"],[15,0,[31,[\"modal__aside__header \",[32,1],\"__aside__header\"]]],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"canGoBack\"]]],[[\"use\",\"duration\"],[[32,0,[\"fadeTransition\"]],200]],[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[8,\"icon-button\",[[16,0,[31,[[32,0,[\"className\"]],\"__header__back-button modal__aside__header__back-button\"]]]],[[\"@theme\",\"@icon\",\"@action\"],[\"white\",\"long-arrow-left:fa:far\",[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[8,\"icon-button\",[[16,0,[31,[[32,0,[\"className\"]],\"__header__close-button modal__aside__header__close-button\"]]]],[[\"@theme\",\"@icon\",\"@modal\"],[\"white\",\"times:fa:fal\",[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n  \"],[18,4,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"animated-if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/modal/aside.hbs"
    }
  });

  _exports.default = _default;
});