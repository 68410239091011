define("@fantasy-account/webapp/templates/components/label-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iG+mlyUc",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,0],[[32,0,[\"icon\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"icon\",[[24,0,\"label__icon\"]],[[\"@icon\"],[[32,0,[\"icon\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,0],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"label__text\"],[12],[18,1,null],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"label__text\"],[12],[1,[32,0,[\"text\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/label-view.hbs"
    }
  });

  _exports.default = _default;
});