define("@fantasy-account/webapp/templates/components/verification/form/identity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Uya0DPb6",
    "block": "{\"symbols\":[\"Fields\"],\"statements\":[[8,\"form\",[],[[\"@delegate\"],[[32,0]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"TextField\"]],[[4,[38,1],null,[[\"delay\"],[0.5]]]],[[\"@theme\",\"@name\",\"@label\",\"@max\"],[\"fantasy-account\",\"firstName\",[30,[36,0],[\"verification.identity.field.first-name\"],null],50]],null],[2,\"\\n  \"],[8,[32,1,[\"TextField\"]],[],[[\"@theme\",\"@name\",\"@label\",\"@max\"],[\"fantasy-account\",\"lastName\",[30,[36,0],[\"verification.identity.field.last-name\"],null],50]],null],[2,\"\\n  \"],[8,[32,1,[\"DateDropdown\"]],[],[[\"@theme\",\"@name\",\"@label\",\"@block\"],[\"fantasy-account\",\"bornAt\",[30,[36,0],[\"verification.identity.field.born-at\"],null],true]],null],[2,\"\\n\\n  \"],[8,[32,1,[\"Submit\"]],[],[[\"@label\",\"@theme\",\"@modifier\"],[[30,[36,0],[\"continue\"],null],\"fantasy-account\",\"flat md\"]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"autofocus\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/verification/form/identity.hbs"
    }
  });

  _exports.default = _default;
});