define("@fantasy-account/webapp/templates/components/account/profile/modal/picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I+6RWDow",
    "block": "{\"symbols\":[\"ModalView\"],\"statements\":[[8,\"modal\",[[24,0,\"images-picker-modal\"]],[[\"@name\",\"@delegate\",\"@animation\",\"@modifier\"],[[32,0,[\"modalName\"]],[32,0],\"slide-up\",\"rounded screen\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Header\"]],[[24,0,\"images-picker-modal__header\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"icon-button\",[[24,0,\"modal__header__close-button\"]],[[\"@icon\",\"@modal\"],[\"times:fa:fal\",[32,0,[\"modalName\"]]]],null],[2,\"\\n    \"],[8,\"bold-heading\",[[24,0,\"modal__header__heading\"]],[[\"@tag\"],[\"h2\"]],[[\"default\"],[{\"statements\":[[1,[32,0,[\"heading\"]]]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Body\"]],[[24,0,\"images-picker-modal__body\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,1],[[32,0,[\"imagesCollectionComponent\"]]],[[\"images\",\"selected\",\"onChange\"],[[32,0,[\"filteredImages\"]],[32,0,[\"selection\"]],[30,[36,0],[[32,0],\"imageSelected\"],null]]]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Footer\"]],[[24,0,\"images-picker-modal__footer\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"contained-button\",[],[[\"@theme\",\"@disabled\",\"@modifier\",\"@action\"],[\"accent\",[32,0,[\"doneButtonDisabled\"]],\"centered half-block bold flat xl uppercase done-action\",[30,[36,0],[[32,0],\"done\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,2],[\"done\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"component\",\"t\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/account/profile/modal/picker.hbs"
    }
  });

  _exports.default = _default;
});