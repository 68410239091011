define("@fantasy-account/webapp/templates/components/fantasy-account/amount/picker/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QKuSzXSr",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"li\"],[16,0,[32,0,[\"classes\"]]],[17,1],[4,[38,0],[\"click\",[32,0,[\"click\"]]],null],[12],[2,\"\\n  \"],[8,\"radio-indicator\",[[16,0,[31,[[32,0,[\"className\"]],\"__radio-indicator\"]]]],[[],[]],null],[2,\"\\n\\n\"],[6,[37,1],[[32,0,[\"isCustom\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[15,0,[31,[[32,0,[\"className\"]],\"__label\"]]],[12],[2,\"Custom Amount\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"fantasy-account/amount\",[[16,0,[31,[[32,0,[\"className\"]],\"__amount\"]]]],[[\"@value\",\"@modifier\"],[[32,0,[\"value\"]],\"xl\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/fantasy-account/amount/picker/item.hbs"
    }
  });

  _exports.default = _default;
});