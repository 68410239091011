define("@fantasy-account/webapp/templates/components/app-bar/top/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kfnh1uls",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],[[30,[36,0],[[32,0,[\"app-bar\",\"back-item\"]],\"expected `this.app-bar.back-item` to be a contextual component but found a string. Did you mean `(component this.app-bar.back-item)`? ('@fantasy-account/webapp/templates/components/app-bar/top/account.hbs' @ L1:C2) \"],null]],[[\"route\",\"modifier\",\"theme\"],[\"account.verification\",\"size-24\",\"muted\"]]]],[2,\"\\n\\n\"],[1,[30,[36,1],[[30,[36,0],[[32,0,[\"app-bar\",\"brand\"]],\"expected `this.app-bar.brand` to be a contextual component but found a string. Did you mean `(component this.app-bar.brand)`? ('@fantasy-account/webapp/templates/components/app-bar/top/account.hbs' @ L3:C2) \"],null]],[[\"kind\",\"alt\",\"modifier\"],[\"fantasy-account\",\"Fantasy Account Logo\",\"centered\"]]]],[2,\"\\n\\n\"],[6,[37,2],[[32,0,[\"fantasyAccount\",\"embedded\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"icon-link\",[[24,0,\"app-bar__close-button\"]],[[\"@icon\",\"@route\",\"@dismiss\",\"@modifier\",\"@theme\"],[\"times:fa:fal\",[32,0,[\"fantasyAccount\",\"backRoute\"]],true,\"size-24\",\"muted\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-assert-implicit-component-helper-argument\",\"component\",\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/app-bar/top/account.hbs"
    }
  });

  _exports.default = _default;
});