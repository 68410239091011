define("@fantasy-account/webapp/templates/components/card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v+dMLq5X",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,3],null,[[\"header\",\"body\",\"Header\",\"Body\",\"Footer\"],[[30,[36,0],[\"card/header\"],[[\"title\",\"subtitle\"],[[35,2],[35,1]]]],[30,[36,0],[\"card/body\"],null],[30,[36,0],[\"card/header\"],[[\"title\",\"subtitle\"],[[35,2],[35,1]]]],[30,[36,0],[\"card/body\"],null],[30,[36,0],[\"card/footer\"],null]]]]]]],\"hasEval\":false,\"upvars\":[\"component\",\"subtitle\",\"title\",\"hash\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/card.hbs"
    }
  });

  _exports.default = _default;
});