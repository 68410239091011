define("@fantasy-account/webapp/templates/components/fantasy-account/wizard/form-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eVWWDriq",
    "block": "{\"symbols\":[\"@label\",\"&attrs\",\"&default\",\"@onChange\"],\"statements\":[[11,\"div\"],[24,0,\"fa-wizard-form-field\"],[17,2],[12],[2,\"\\n\"],[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"fa-wizard-form-field__label\"],[12],[2,\"\\n      \"],[1,[32,1]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"fa-wizard-form-field__box\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"fa-wizard-form-field__content\"],[12],[2,\"\\n      \"],[18,3,null],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[8,\"text-button\",[[24,0,\"fa-wizard-form-field__button\"]],[[\"@modifier\",\"@action\"],[\"xs muted uppercase\",[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,1],[\"change\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"t\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/fantasy-account/wizard/form-field.hbs"
    }
  });

  _exports.default = _default;
});