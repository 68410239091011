define("@fantasy-account/webapp/components/bold-heading", ["exports", "@playon/framework/components/heading"], function (_exports, _heading) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _heading.default.extend({
    /**
     * @public
     * @since 1.0.0
     * @type {String}
     */
    style: "bold"
  });

  _exports.default = _default;
});