define("@fantasy-account/webapp/mixins/index", ["exports", "@playon/framework/mixins"], function (_exports, _mixins) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "Fetchable", {
    enumerable: true,
    get: function () {
      return _mixins.Fetchable;
    }
  });
  Object.defineProperty(_exports, "HasFastboot", {
    enumerable: true,
    get: function () {
      return _mixins.HasFastboot;
    }
  });
});