define("@fantasy-account/webapp/templates/components/quick-deposit/wizard/amount", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MT3lW8Gy",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[32,0,[\"className\"]]],[17,1],[12],[2,\"\\n  \"],[8,\"fantasy-account/amount/default-picker\",[],[[\"@heading\",\"@customAmountLabel\",\"@value\",\"@onChange\"],[\"Amount\",\"Enter Amount to Deposit\",[32,0,[\"wizard\",\"amount\"]],[32,0,[\"wizard\",\"selectAmount\"]]]],null],[2,\"\\n\\n  \"],[10,\"footer\"],[15,0,[31,[[32,0,[\"className\"]],\"__footer\"]]],[12],[2,\"\\n    \"],[8,\"contained-button\",[[16,0,[31,[[32,0,[\"className\"]],\"__back-button\"]]]],[[\"@theme\",\"@modifier\",\"@action\"],[\"fantasy-account-light\",\"flat\",[30,[36,0],[[32,0,[\"close\"]],true],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      Back\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"contained-button\",[[16,0,[31,[[32,0,[\"className\"]],\"__select-button\"]]]],[[\"@theme\",\"@enabled\",\"@modifier\",\"@action\"],[\"fantasy-account\",[32,0,[\"step\",\"filled\"]],\"flat\",[30,[36,0],[[32,0,[\"close\"]],false],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      Select this Amount\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/quick-deposit/wizard/amount.hbs"
    }
  });

  _exports.default = _default;
});