define("@fantasy-account/webapp/templates/components/description-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3ARPgJcQ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,8],null,[[\"term\",\"description\",\"item\"],[[30,[36,6],[\"description-list-term\"],[[\"bordered\",\"alignment\",\"classLayout\"],[[35,7],[35,4],[35,3]]]],[30,[36,6],[\"description-list-description\"],[[\"bordered\",\"alignment\",\"classLayout\"],[[35,7],[35,1],[35,0]]]],[30,[36,6],[\"description-list-item\"],[[\"termBordered\",\"termAlignment\",\"termLayout\",\"descriptionBordered\",\"descriptionAlignment\",\"descriptionLayout\"],[[35,5],[35,4],[35,3],[35,2],[35,1],[35,0]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"descriptionLayout\",\"descriptionAlignment\",\"descriptionBordered\",\"termLayout\",\"termAlignment\",\"termBordered\",\"component\",\"bordered\",\"hash\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/description-list.hbs"
    }
  });

  _exports.default = _default;
});