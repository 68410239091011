define("@fantasy-account/webapp/templates/components/verification/document/picker/selfie", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v2Y358ip",
    "block": "{\"symbols\":[\"&attrs\",\"@supportsCamera\"],\"statements\":[[11,\"div\"],[16,0,[32,0,[\"className\"]]],[17,1],[12],[2,\"\\n  \"],[8,\"animated/text\",[],[[\"@value\"],[[30,[36,0],[[32,0,[\"message\"]]],[[\"documentName\",\"supportsCamera\"],[\"Selfie\",[32,2]]]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/verification/document/picker/selfie.hbs"
    }
  });

  _exports.default = _default;
});