define("@fantasy-account/webapp/templates/components/menu/separator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Cx4drF8R",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"li\"],[16,0,[32,0,[\"className\"]]],[17,1],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/menu/separator.hbs"
    }
  });

  _exports.default = _default;
});