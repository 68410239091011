define("@fantasy-account/webapp/adapters/ott/f1/show", ["exports", "@fantasy-account/webapp/adapters/ott/application", "@playon/framework/ott/services/f1/adapters/show"], function (_exports, _application, _show) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @public
   *
   * @class OttF1SeasonAdapter
   * @extends OttAdapter
   * @mixes OttF1ShowAdapterMixin
   *
   * @since 1.0.0
   */
  var _default = _application.default.extend(_show.default, {});

  _exports.default = _default;
});