define("@fantasy-account/webapp/templates/components/deposit/wizard/billing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2GTqc2Wo",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[32,0,[\"className\"]]],[17,1],[12],[2,\"\\n  \"],[8,\"contained-button\",[[16,0,[31,[[32,0,[\"className\"]],\"__continue-button\"]]]],[[\"@modifier\",\"@action\"],[\"flat block\",[32,0,[\"next\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"continue\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/deposit/wizard/billing.hbs"
    }
  });

  _exports.default = _default;
});