define("@fantasy-account/webapp/templates/components/quick-deposit/wizard/payment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "epmoH53F",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[32,0,[\"className\"]]],[17,1],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[[32,0,[\"className\"]],\"__picker\"]]],[12],[2,\"\\n    \"],[8,\"payment-methods/default-picker\",[],[[\"@selected\",\"@onChange\"],[[32,0,[\"wizard\",\"paymentMethod\"]],[32,0,[\"wizard\",\"changePaymentMethod\"]]]],null],[2,\"\\n    \"],[8,\"payment-method/form\",[],[[\"@type\",\"@paymentMethod\"],[\"deposit\",[32,0,[\"wizard\",\"paymentMethod\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"footer\"],[15,0,[31,[[32,0,[\"className\"]],\"__footer\"]]],[12],[2,\"\\n    \"],[8,\"contained-button\",[[16,0,[31,[[32,0,[\"className\"]],\"__back-button\"]]]],[[\"@theme\",\"@modifier\",\"@action\"],[\"fantasy-account-light\",\"flat\",[30,[36,0],[[32,0,[\"close\"]],true],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      Back\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"contained-button\",[[16,0,[31,[[32,0,[\"className\"]],\"__select-button\"]]]],[[\"@theme\",\"@enabled\",\"@modifier\",\"@action\"],[\"fantasy-account\",[32,0,[\"step\",\"filled\"]],\"flat\",[30,[36,0],[[32,0,[\"close\"]],false],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      Use this Payment Method\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/quick-deposit/wizard/payment.hbs"
    }
  });

  _exports.default = _default;
});