define("@fantasy-account/webapp/templates/components/animated/text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aKFFKRMS",
    "block": "{\"symbols\":[\"v\"],\"statements\":[[8,\"animated-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"animated-value\",[],[[\"@value\",\"@use\"],[[32,0,[\"value\"]],[32,0,[\"transition\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[12],[1,[32,1]],[13],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/animated/text.hbs"
    }
  });

  _exports.default = _default;
});