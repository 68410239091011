define("@fantasy-account/webapp/templates/components/inline-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "koA1ltbM",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[35,10]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,[[30,[36,6],null,[[\"text-field\",\"confirm-button\",\"cancel-button\"],[[30,[36,5],[\"text-field\"],[[\"placeholder\",\"error\",\"value\",\"style\",\"nestedModifiers\"],[[35,0],[35,9],[35,1],\"filled\",\"transparent fit\"]]],[30,[36,5],[\"icon-button\",[35,8]],[[\"nestedModifiers\",\"action\"],[\"confirm\",[30,[36,3],[[32,0],\"confirm\"],null]]]],[30,[36,5],[\"icon-button\",[35,7]],[[\"nestedModifiers\",\"action\"],[\"cancel\",[30,[36,3],[[32,0],\"exitEditMode\"],null]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[34,1]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[18,1,[[30,[36,6],null,[[\"edit-button\"],[[30,[36,5],[\"icon-button\"],[[\"icon\",\"nestedModifiers\",\"action\"],[[35,4],\"edit\",[30,[36,3],[[32,0],\"enterEditMode\"],null]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"placeholder\",\"value\",\"if\",\"action\",\"editIcon\",\"component\",\"hash\",\"cancelIcon\",\"confirmIcon\",\"error\",\"editing\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/inline-editor.hbs"
    }
  });

  _exports.default = _default;
});