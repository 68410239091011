define("@fantasy-account/webapp/templates/components/advertisement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BkA6ddeH",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[6,[37,1],[[32,0,[\"shouldDisplay\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"div\"],[16,1,[32,0,[\"advertisement\",\"elementId\"]]],[16,0,[32,0,[\"classes\"]]],[17,1],[4,[38,0],[[32,0,[\"didInsert\"]]],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/advertisement.hbs"
    }
  });

  _exports.default = _default;
});