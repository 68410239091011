define("@fantasy-account/webapp/components/bar/space", ["exports", "@playon/framework/components/bar/space"], function (_exports, _space) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _space.default;
    }
  });
});