define("@fantasy-account/webapp/components/payment-method/picker-item", ["exports", "@fantasy-account/engine/components/payment-method/picker-item"], function (_exports, _pickerItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _pickerItem.default;
    }
  });
});