define("@fantasy-account/webapp/components/list-item-editable", ["exports", "@playon/framework/components/list-item-editable"], function (_exports, _listItemEditable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _listItemEditable.default;
    }
  });
});