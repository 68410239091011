define("@fantasy-account/webapp/templates/components/forgot-password/form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OzKnZzdB",
    "block": "{\"symbols\":[\"Field\"],\"statements\":[[8,\"form\",[[24,0,\"form__forgot-password\"]],[[\"@delegate\"],[[32,0]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[32,0,[\"resetEmailSent\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"alert\",[],[[\"@message\",\"@icon\",\"@theme\",\"@style\"],[[30,[36,0],[\"auth.forgot-password.email-sent-message\"],null],\"check:fa:far\",\"fantasy-account\",\"success\"]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,[32,1,[\"Text\"]],[[4,[38,1],null,[[\"delay\"],[0.5]]]],[[\"@theme\",\"@name\",\"@label\",\"@max\",\"@type\"],[\"fantasy-account\",\"email\",[30,[36,0],[\"auth.email\"],null],50,\"email\"]],null],[2,\"\\n    \"],[8,[32,1,[\"Submit\"]],[],[[\"@label\",\"@theme\",\"@modifier\"],[[30,[36,0],[\"auth.button.reset-password\"],null],\"fantasy-account\",\"flat block\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"autofocus\",\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/forgot-password/form.hbs"
    }
  });

  _exports.default = _default;
});