define("@fantasy-account/webapp/components/payment-methods/picker", ["exports", "@fantasy-account/engine/components/payment-methods/picker"], function (_exports, _picker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _picker.default;
    }
  });
});