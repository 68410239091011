define("@fantasy-account/webapp/helpers/index", ["exports", "@playon/framework/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "equal", {
    enumerable: true,
    get: function () {
      return _helpers.equal;
    }
  });
  Object.defineProperty(_exports, "gt", {
    enumerable: true,
    get: function () {
      return _helpers.gt;
    }
  });
  Object.defineProperty(_exports, "progress", {
    enumerable: true,
    get: function () {
      return _helpers.progress;
    }
  });
});