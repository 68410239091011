define("@fantasy-account/webapp/components/account/settings/language/selector", ["exports", "@fantasy-account/engine/components/account/settings/language/selector"], function (_exports, _selector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _selector.default;
    }
  });
});