define("@fantasy-account/webapp/trait/index", ["exports", "@playon/framework/trait"], function (_exports, _trait) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "concatenated", {
    enumerable: true,
    get: function () {
      return _trait.concatenated;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _trait.default;
    }
  });
  Object.defineProperty(_exports, "delegatable", {
    enumerable: true,
    get: function () {
      return _trait.delegatable;
    }
  });
  Object.defineProperty(_exports, "evented", {
    enumerable: true,
    get: function () {
      return _trait.evented;
    }
  });
  Object.defineProperty(_exports, "uid", {
    enumerable: true,
    get: function () {
      return _trait.uid;
    }
  });
});