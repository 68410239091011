define("@fantasy-account/webapp/templates/components/payment-method/card/list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xle0/aIx",
    "block": "{\"symbols\":[\"@className\"],\"statements\":[[8,\"logo\",[[16,0,[31,[[32,1],\"__network\"]]]],[[\"@kind\",\"@name\"],[[32,0,[\"paymentMethod\",\"network\"]],\"card\"]],null],[2,\"\\n\\n\"],[10,\"div\"],[15,0,[31,[[32,1],\"__value\"]]],[12],[2,\"•••• \"],[1,[32,0,[\"paymentMethod\",\"placeholder\"]]],[13],[2,\"\\n\\n\"],[6,[37,1],[[32,0,[\"paymentMethod\",\"expired\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[[32,1],\"__status \",[32,1],\"__status--expired\"]]],[12],[2,\"Expired\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"time\"],[15,\"datetime\",[30,[36,0],[[32,0,[\"paymentMethod\",\"expirationDate\"]],\"YYYY-MM\"],null]],[15,0,[31,[[32,1],\"__expiration-date\"]]],[12],[2,\"\\n    \"],[1,[30,[36,0],[[32,0,[\"paymentMethod\",\"expirationDate\"]],\"MM/YY\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"format\",\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/payment-method/card/list-item.hbs"
    }
  });

  _exports.default = _default;
});