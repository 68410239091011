define("@fantasy-account/webapp/instance-initializers/i18n", ["exports", "@playon/framework/instance-initializers/i18n"], function (_exports, _i18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "after", {
    enumerable: true,
    get: function () {
      return _i18n.after;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _i18n.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function () {
      return _i18n.initialize;
    }
  });
});