define("@fantasy-account/webapp/templates/components/tab-bar-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J4S16eD3",
    "block": "{\"symbols\":[],\"statements\":[[10,\"a\"],[15,6,[34,3]],[15,\"title\",[34,4]],[15,\"rel\",[34,5]],[15,\"tabindex\",[34,6]],[15,\"target\",[34,7]],[14,0,\"tab-bar__item__link\"],[12],[2,\"\\n\"],[6,[37,8],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,2],null,[[\"class\",\"icon\",\"isClassIconType\"],[\"tab-bar__item__icon\",[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,8],[[32,0,[\"label\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"tab-bar__item__label\"],[12],[2,\"\\n      \"],[1,[32,0,[\"label\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"isClassIconType\",\"icon\",\"icon-view\",\"url\",\"title\",\"rel\",\"tabindex\",\"target\",\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/tab-bar-link.hbs"
    }
  });

  _exports.default = _default;
});