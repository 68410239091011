define("@fantasy-account/webapp/components/contained-dropdown", ["exports", "@playon/framework/components/dropdown"], function (_exports, _dropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dropdown.default.extend({
    /**
     * @public
     * @since 1.0.0
     * @type {String}
     */
    type: "contained"
  });

  _exports.default = _default;
});