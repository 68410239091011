define("@fantasy-account/webapp/templates/components/bottom-navigation-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3h2+8jKU",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"bottom-navigation__item__icon-container\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[[35,0]],[[\"class\"],[\"bottom-navigation__item__icon\"]]]],[2,\"\\n\\n\"],[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"badge\",[[24,0,\"bottom-navigation__item__badge\"]],[[\"@text\",\"@shape\"],[[32,0,[\"badgeContent\"]],[32,0,[\"badgeShape\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],[[32,0,[\"label\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"bottom-navigation__item__label\"],[12],[2,\"\\n    \"],[1,[32,0,[\"label\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"currentIcon\",\"icon-view\",\"badge\",\"if\",\"icon\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/bottom-navigation-item.hbs"
    }
  });

  _exports.default = _default;
});