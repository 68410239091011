define("@fantasy-account/webapp/templates/components/modal/body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Vg9CxN5+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,4],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,[[30,[36,2],null,[[\"component\"],[[30,[36,3],[[35,3]],[[\"modal\",\"params\"],[[35,1],[35,0]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,3],[[35,3]],[[\"modal\",\"params\"],[[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[18,1,[[30,[36,2],null,[[\"modal\",\"params\"],[[35,1],[35,0]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"params\",\"modal\",\"hash\",\"component\",\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/modal/body.hbs"
    }
  });

  _exports.default = _default;
});