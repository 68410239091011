define("@fantasy-account/webapp/templates/components/verification/status-stepper/identity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4EQchrf7",
    "block": "{\"symbols\":[],\"statements\":[[8,\"verification/status-stepper/item\",[],[[\"@classes\",\"@icon\",\"@label\",\"@value\"],[[32,0,[\"classes\"]],[32,0,[\"icon\"]],[32,0,[\"label\"]],[32,0,[\"value\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/verification/status-stepper/identity.hbs"
    }
  });

  _exports.default = _default;
});