define("@fantasy-account/webapp/templates/components/app-bar/top/web", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8LBCfUlp",
    "block": "{\"symbols\":[],\"statements\":[[10,\"a\"],[14,0,\"app-bar__index\"],[14,6,\"/\"],[12],[2,\"\\n  \"],[8,\"logo\",[[24,0,\"app-bar__brand\"]],[[\"@kind\",\"@alt\",\"@modifier\"],[\"fantasy-account\",\"Fantasy Account Logo\",\"centered\"]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"contained-link\",[],[[\"@label\",\"@route\",\"@theme\",\"@modifier\"],[\"Log In\",\"login\",\"accent\",\"flat\"]],null],[2,\" \"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/app-bar/top/web.hbs"
    }
  });

  _exports.default = _default;
});