define("@fantasy-account/webapp/authorization/index", ["exports", "@playon/framework/authorization"], function (_exports, _authorization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "Policy", {
    enumerable: true,
    get: function () {
      return _authorization.Policy;
    }
  });
});