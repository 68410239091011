define("@fantasy-account/webapp/templates/components/flag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DmIX5PZv",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[6,[37,1],[[32,0,[\"country\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"span\"],[16,0,[31,[[32,0,[\"classes\"]],\" \",[32,0,[\"countryClass\"]]]]],[17,1],[4,[38,0],[[32,0,[\"country\",\"name\"]]],[[\"skip\"],[[32,0,[\"shouldSkipTooltip\"]]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"tooltip\",\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/flag.hbs"
    }
  });

  _exports.default = _default;
});