define("@fantasy-account/webapp/components/homepage", ["exports", "@playon/framework/components"], function (_exports, _components) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Homepage extends _components.Component {}

  var _default = Homepage;
  _exports.default = _default;
});