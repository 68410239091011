define("@fantasy-account/webapp/components/video-frame", ["exports", "@playon/framework/components/video-frame"], function (_exports, _videoFrame) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _videoFrame.default;
    }
  });
});