define("@fantasy-account/webapp/templates/components/payment-methods/picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CSuHIA70",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[32,0,[\"className\"]]],[17,1],[12],[2,\"\\n  \"],[8,\"animated-container\",[[16,0,[31,[[32,0,[\"className\"]],\"__list\"]]]],[[\"@tag\"],[\"ul\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,2,[[30,[36,1],null,[[\"PaymentMethod\",\"Card\",\"Skrill\",\"Neteller\"],[[30,[36,0],[\"payment-method/picker-item\"],[[\"selection\",\"allowedEmptySelection\",\"onSelect\",\"onDeselect\"],[[32,0,[\"selected\"]],[32,0,[\"allowedEmptySelection\"]],[32,0,[\"selectItem\"]],[32,0,[\"selectItem\"]]]]],[30,[36,0],[\"payment-method/picker-item\"],[[\"type\",\"selection\",\"allowedEmptySelection\",\"onSelect\",\"onDeselect\"],[\"card\",[32,0,[\"selected\"]],[32,0,[\"allowedEmptySelection\"]],[32,0,[\"selectItem\"]],[32,0,[\"selectItem\"]]]]],[30,[36,0],[\"payment-method/picker-item\"],[[\"type\",\"selection\",\"allowedEmptySelection\",\"onSelect\",\"onDeselect\"],[\"skrill\",[32,0,[\"selected\"]],[32,0,[\"allowedEmptySelection\"]],[32,0,[\"selectItem\"]],[32,0,[\"selectItem\"]]]]],[30,[36,0],[\"payment-method/picker-item\"],[[\"type\",\"selection\",\"allowedEmptySelection\",\"onSelect\",\"onDeselect\"],[\"neteller\",[32,0,[\"selected\"]],[32,0,[\"allowedEmptySelection\"]],[32,0,[\"selectItem\"]],[32,0,[\"selectItem\"]]]]]]]]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/payment-methods/picker.hbs"
    }
  });

  _exports.default = _default;
});