define("@fantasy-account/webapp/templates/components/carousel/bullets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DMI8ukfZ",
    "block": "{\"symbols\":[\"index\",\"&attrs\"],\"statements\":[[11,\"div\"],[24,0,\"carousel__bullets\"],[24,\"data-glide-el\",\"controls[nav]\"],[17,2],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"bulletsCountArray\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"button\"],[14,0,\"carousel__bullets__bullet\"],[15,\"data-glide-dir\",[31,[\"=\",[32,1]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/carousel/bullets.hbs"
    }
  });

  _exports.default = _default;
});