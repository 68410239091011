define("@fantasy-account/webapp/templates/components/app-bar/top/account/deposit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JCDBLttv",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],[[30,[36,0],[[32,0,[\"app-bar\",\"brand\"]],\"expected `this.app-bar.brand` to be a contextual component but found a string. Did you mean `(component this.app-bar.brand)`? ('@fantasy-account/webapp/templates/components/app-bar/top/account/deposit.hbs' @ L1:C2) \"],null]],[[\"kind\",\"alt\",\"modifier\"],[\"fantasy-account\",\"Fantasy Account Logo\",\"centered\"]]]],[2,\"\\n\"],[1,[30,[36,1],[[30,[36,0],[[32,0,[\"app-bar\",\"close-item\"]],\"expected `this.app-bar.close-item` to be a contextual component but found a string. Did you mean `(component this.app-bar.close-item)`? ('@fantasy-account/webapp/templates/components/app-bar/top/account/deposit.hbs' @ L2:C2) \"],null]],[[\"route\",\"modifier\",\"theme\"],[\"account.settings\",\"size-24\",\"muted\"]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-assert-implicit-component-helper-argument\",\"component\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/app-bar/top/account/deposit.hbs"
    }
  });

  _exports.default = _default;
});