define("@fantasy-account/webapp/templates/components/list-row-part", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p6EXEINa",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"strong\"],[14,0,\"list__row__part__title\"],[12],[2,\"\\n  \"],[1,[34,0]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"list__row__part__content\"],[12],[2,\"\\n  \"],[1,[34,1]],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"title\",\"content\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/list-row-part.hbs"
    }
  });

  _exports.default = _default;
});