define("@fantasy-account/webapp/templates/components/tabs-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a1mlI2Ch",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,2],null,[[\"item\",\"Item\"],[[30,[36,1],[\"tab-content\"],[[\"tabSelected\"],[[35,0]]]],[30,[36,1],[\"tab-content\"],[[\"tabSelected\"],[[35,0]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"value\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/tabs-container.hbs"
    }
  });

  _exports.default = _default;
});