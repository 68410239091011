define("@fantasy-account/webapp/templates/components/balance/wizard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JkOf1IJA",
    "block": "{\"symbols\":[\"@heading\",\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[32,0,[\"classes\"]]],[17,2],[12],[2,\"\\n\"],[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"bold-heading\",[[16,0,[31,[[32,0,[\"className\"]],\"__heading\"]]]],[[\"@tag\"],[\"h1\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,1]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"main\"],[15,0,[31,[[32,0,[\"className\"]],\"__content\"]]],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"wizard\",\"linear\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"fantasy-account/wizard/stepper\",[],[[\"@wizard\"],[[32,0,[\"wizard\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"fantasy-account/card\",[[16,0,[31,[[32,0,[\"className\"]],\"__card\"]]]],[[\"@modifier\"],[\"lg unbordered-md\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"fantasy-account/wizard/view\",[],[[\"@wizard\"],[[32,0,[\"wizard\"]]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"aside\"],[15,0,[31,[[32,0,[\"className\"]],\"__sidebar\"]]],[12],[2,\"\\n    \"],[8,\"fantasy-account/card\",[[16,0,[31,[[32,0,[\"className\"]],\"__summary\"]]]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"balance/summary\",[],[[\"@type\",\"@amount\"],[[32,0,[\"summaryType\"]],[32,0,[\"wizard\",\"amount\"]]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/balance/wizard.hbs"
    }
  });

  _exports.default = _default;
});