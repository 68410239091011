define("@fantasy-account/webapp/mixins/themeable", ["exports", "@playon/framework/mixins/themeable"], function (_exports, _themeable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _themeable.default;
    }
  });
});