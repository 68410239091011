define("@fantasy-account/webapp/ott/index", ["exports", "@playon/framework/ott"], function (_exports, _ott) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "OttAdapterAbstract", {
    enumerable: true,
    get: function () {
      return _ott.OttAdapterAbstract;
    }
  });
  Object.defineProperty(_exports, "OttItemContract", {
    enumerable: true,
    get: function () {
      return _ott.OttItemContract;
    }
  });
  Object.defineProperty(_exports, "OttPlanContract", {
    enumerable: true,
    get: function () {
      return _ott.OttPlanContract;
    }
  });
  Object.defineProperty(_exports, "OttRepository", {
    enumerable: true,
    get: function () {
      return _ott.OttRepository;
    }
  });
  Object.defineProperty(_exports, "OttStore", {
    enumerable: true,
    get: function () {
      return _ott.OttStore;
    }
  });
  Object.defineProperty(_exports, "OttSubscriptionCotract", {
    enumerable: true,
    get: function () {
      return _ott.OttSubscriptionCotract;
    }
  });
  Object.defineProperty(_exports, "OttWeekContract", {
    enumerable: true,
    get: function () {
      return _ott.OttWeekContract;
    }
  });
  Object.defineProperty(_exports, "Service", {
    enumerable: true,
    get: function () {
      return _ott.Service;
    }
  });
  Object.defineProperty(_exports, "ServiceSeeds", {
    enumerable: true,
    get: function () {
      return _ott.ServiceSeeds;
    }
  });
  Object.defineProperty(_exports, "inject", {
    enumerable: true,
    get: function () {
      return _ott.inject;
    }
  });
});