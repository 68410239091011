define("@fantasy-account/webapp/templates/components/list-accessory-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4oRro6s8",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/list-accessory-view.hbs"
    }
  });

  _exports.default = _default;
});