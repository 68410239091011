define("@fantasy-account/webapp/mixins/components/index", ["exports", "@playon/framework/mixins/components"], function (_exports, _components) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "CanPresentMenus", {
    enumerable: true,
    get: function () {
      return _components.CanPresentMenus;
    }
  });
  Object.defineProperty(_exports, "CanPresentSheets", {
    enumerable: true,
    get: function () {
      return _components.CanPresentSheets;
    }
  });
  Object.defineProperty(_exports, "CanPresentTours", {
    enumerable: true,
    get: function () {
      return _components.CanPresentTours;
    }
  });
  Object.defineProperty(_exports, "CanRefreshScrollView", {
    enumerable: true,
    get: function () {
      return _components.CanRefreshScrollView;
    }
  });
  Object.defineProperty(_exports, "DismissibleDelegate", {
    enumerable: true,
    get: function () {
      return _components.DismissibleDelegate;
    }
  });
  Object.defineProperty(_exports, "HasHover", {
    enumerable: true,
    get: function () {
      return _components.HasHover;
    }
  });
  Object.defineProperty(_exports, "HasSelectables", {
    enumerable: true,
    get: function () {
      return _components.HasSelectables;
    }
  });
  Object.defineProperty(_exports, "Loadable", {
    enumerable: true,
    get: function () {
      return _components.Loadable;
    }
  });
  Object.defineProperty(_exports, "MenuDelegate", {
    enumerable: true,
    get: function () {
      return _components.MenuDelegate;
    }
  });
  Object.defineProperty(_exports, "ModalDelegate", {
    enumerable: true,
    get: function () {
      return _components.ModalDelegate;
    }
  });
  Object.defineProperty(_exports, "Modifiable", {
    enumerable: true,
    get: function () {
      return _components.Modifiable;
    }
  });
  Object.defineProperty(_exports, "NavigationDrawerDelegate", {
    enumerable: true,
    get: function () {
      return _components.NavigationDrawerDelegate;
    }
  });
  Object.defineProperty(_exports, "Selectable", {
    enumerable: true,
    get: function () {
      return _components.Selectable;
    }
  });
  Object.defineProperty(_exports, "SheetDelegate", {
    enumerable: true,
    get: function () {
      return _components.SheetDelegate;
    }
  });
  Object.defineProperty(_exports, "Themeable", {
    enumerable: true,
    get: function () {
      return _components.Themeable;
    }
  });
});