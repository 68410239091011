define("@fantasy-account/webapp/templates/components/app-bar/more-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tAre/Dl2",
    "block": "{\"symbols\":[\"Menu\",\"item\"],\"statements\":[[8,\"text-button\",[],[[\"@label\",\"@icon\",\"@menu\",\"@modifier\",\"@theme\",\"@action\"],[[32,0,[\"label\"]],[32,0,[\"icon\"]],[32,0,[\"menu\"]],[32,0,[\"modifier\"]],[32,0,[\"theme\"]],[32,0,[\"action\"]]]],null],[2,\"\\n\\n\"],[8,\"menu-view\",[],[[\"@delegate\",\"@name\",\"@placement\"],[[32,0,[\"delegate\"]],[32,0,[\"menu\"]],[32,0,[\"placement\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"children\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,2,[\"isRoute\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[8,[32,1,[\"Item\"]],[],[[\"@label\",\"@icon\",\"@route\"],[[32,2,[\"label\"]],\"chevron-right:fa:fal\",[32,2,[\"route\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[8,[32,1,[\"Item\"]],[],[[\"@label\",\"@icon\",\"@href\",\"@target\"],[[32,2,[\"label\"]],\"chevron-right:fa:fal\",[32,2,[\"url\"]],[32,2,[\"target\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/app-bar/more-item.hbs"
    }
  });

  _exports.default = _default;
});