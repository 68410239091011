define("@fantasy-account/webapp/templates/components/listing-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+3b//XkV",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,2],[[35,1]],[[\"nestedModifiers\",\"class\"],[[35,0],\"listing__row__icon listing__row__icon--left\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],[[32,0,[\"label\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"listing__row__text\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"listing__row__label\"],[12],[1,[32,0,[\"label\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"iconModifiers\",\"leftIcon\",\"icon-view\",\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/listing-row.hbs"
    }
  });

  _exports.default = _default;
});