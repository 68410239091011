define("@fantasy-account/webapp/templates/components/account/change-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hm0A3Ai7",
    "block": "{\"symbols\":[\"Field\",\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[32,0,[\"className\"]]],[17,2],[12],[2,\"\\n  \"],[8,\"form\",[[16,0,[31,[[32,0,[\"className\"]],\"__form\"]]]],[[\"@delegate\"],[[32,0]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"Text\"]],[[4,[38,1],null,[[\"delay\"],[0.5]]]],[[\"@theme\",\"@name\",\"@label\",\"@max\",\"@type\"],[\"fantasy-account\",\"currentPassword\",[30,[36,0],[\"account.change-password.fields.current-password\"],null],50,\"password\"]],null],[2,\"\\n    \"],[8,[32,1,[\"Text\"]],[],[[\"@theme\",\"@name\",\"@label\",\"@max\",\"@type\"],[\"fantasy-account\",\"password\",[30,[36,0],[\"account.change-password.fields.password\"],null],50,\"password\"]],null],[2,\"\\n    \"],[8,[32,1,[\"Text\"]],[],[[\"@theme\",\"@name\",\"@label\",\"@max\",\"@type\"],[\"fantasy-account\",\"passwordConfirmation\",[30,[36,0],[\"account.change-password.fields.password-confirmation\"],null],50,\"password\"]],null],[2,\"\\n\\n    \"],[8,[32,1,[\"Submit\"]],[[16,0,[31,[[32,0,[\"className\"]],\"__form__submit\"]]]],[[\"@label\",\"@theme\",\"@modifier\"],[[30,[36,0],[\"account.change-password.submit\"],null],\"fantasy-account\",\"flat\"]],null],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"autofocus\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/account/change-password.hbs"
    }
  });

  _exports.default = _default;
});