define("@fantasy-account/webapp/data/index", ["exports", "@playon/framework/data"], function (_exports, _data) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "AuthenticableAdapter", {
    enumerable: true,
    get: function () {
      return _data.AuthenticableAdapter;
    }
  });
  Object.defineProperty(_exports, "BasicAdapter", {
    enumerable: true,
    get: function () {
      return _data.BasicAdapter;
    }
  });
  Object.defineProperty(_exports, "BasicSerializer", {
    enumerable: true,
    get: function () {
      return _data.BasicSerializer;
    }
  });
  Object.defineProperty(_exports, "CountriesSeed", {
    enumerable: true,
    get: function () {
      return _data.CountriesSeed;
    }
  });
  Object.defineProperty(_exports, "Country", {
    enumerable: true,
    get: function () {
      return _data.Country;
    }
  });
  Object.defineProperty(_exports, "Currency", {
    enumerable: true,
    get: function () {
      return _data.Currency;
    }
  });
  Object.defineProperty(_exports, "HasCountrySerializer", {
    enumerable: true,
    get: function () {
      return _data.HasCountrySerializer;
    }
  });
  Object.defineProperty(_exports, "HasCurrencySerializer", {
    enumerable: true,
    get: function () {
      return _data.HasCurrencySerializer;
    }
  });
  Object.defineProperty(_exports, "HasHeadersAdapter", {
    enumerable: true,
    get: function () {
      return _data.HasHeadersAdapter;
    }
  });
  Object.defineProperty(_exports, "HasIncludedModelsSerializer", {
    enumerable: true,
    get: function () {
      return _data.HasIncludedModelsSerializer;
    }
  });
  Object.defineProperty(_exports, "MomentTransform", {
    enumerable: true,
    get: function () {
      return _data.MomentTransform;
    }
  });
  Object.defineProperty(_exports, "TraceableAdapter", {
    enumerable: true,
    get: function () {
      return _data.TraceableAdapter;
    }
  });
  Object.defineProperty(_exports, "User", {
    enumerable: true,
    get: function () {
      return _data.User;
    }
  });
  Object.defineProperty(_exports, "VerifiableSerializer", {
    enumerable: true,
    get: function () {
      return _data.VerifiableSerializer;
    }
  });
});