define("@fantasy-account/webapp/templates/components/app-bar/top/account/subscriptions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "792ULc8/",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[32,0,[\"device\",\"type\",\"mobile\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[30,[36,0],[[32,0,[\"app-bar\",\"menu-item\"]],\"expected `this.app-bar.menu-item` to be a contextual component but found a string. Did you mean `(component this.app-bar.menu-item)`? ('@fantasy-account/webapp/templates/components/app-bar/top/account/subscriptions.hbs' @ L2:C4) \"],null]],[[\"theme\"],[\"muted\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[30,[36,1],[[30,[36,0],[[32,0,[\"app-bar\",\"heading\"]],\"expected `this.app-bar.heading` to be a contextual component but found a string. Did you mean `(component this.app-bar.heading)`? ('@fantasy-account/webapp/templates/components/app-bar/top/account/subscriptions.hbs' @ L5:C2) \"],null]],[[\"title\"],[[30,[36,3],[\"navigation.heading.subscriptions\"],null]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-assert-implicit-component-helper-argument\",\"component\",\"if\",\"t\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/app-bar/top/account/subscriptions.hbs"
    }
  });

  _exports.default = _default;
});