define("@fantasy-account/webapp/instance-initializers/config-icon", ["exports", "@playon/framework/instance-initializers/config-icon"], function (_exports, _configIcon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _configIcon.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function () {
      return _configIcon.initialize;
    }
  });
});