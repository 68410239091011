define("@fantasy-account/webapp/wizards/index", ["exports", "@playon/framework/wizards"], function (_exports, _wizards) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "IndexedWizard", {
    enumerable: true,
    get: function () {
      return _wizards.IndexedWizard;
    }
  });
  Object.defineProperty(_exports, "ModelWizard", {
    enumerable: true,
    get: function () {
      return _wizards.ModelWizard;
    }
  });
  Object.defineProperty(_exports, "Step", {
    enumerable: true,
    get: function () {
      return _wizards.Step;
    }
  });
  Object.defineProperty(_exports, "Wizard", {
    enumerable: true,
    get: function () {
      return _wizards.Wizard;
    }
  });
  Object.defineProperty(_exports, "inject", {
    enumerable: true,
    get: function () {
      return _wizards.inject;
    }
  });
});