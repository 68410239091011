define("@fantasy-account/webapp/templates/components/data-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vePuiTqx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,2],null,[[\"Header\",\"Footer\",\"Body\",\"Row\",\"Cell\",\"Column\",\"Item\",\"Heading\"],[[30,[36,1],[\"data-table-header\"],null],[30,[36,1],[\"data-table-footer\"],null],[30,[36,1],[\"data-table-body\"],null],[30,[36,1],[\"data-table-row\"],null],[30,[36,1],[\"data-table-cell\"],null],[30,[36,1],[\"data-table-cell\"],null],[30,[36,1],[\"data-table-cell\"],null],[30,[36,1],[\"data-table-cell\"],[[\"tagName\",\"onInitializeCell\",\"onSort\"],[\"th\",[30,[36,0],[[32,0],\"initializeHeading\"],null],[30,[36,0],[[32,0],\"sortColumn\"],null]]]]]]]]]],\"hasEval\":false,\"upvars\":[\"action\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/data-table.hbs"
    }
  });

  _exports.default = _default;
});