define("@fantasy-account/webapp/templates/components/accordion-panel/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "33ZnC+X4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"accordion-panel__header__content\"],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"icon-view\",[[24,0,\"accordion-panel__header__toggle-icon\"]],[[\"@icon\"],[\"chevron-down:fa:far\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"icon\",\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/accordion-panel/header.hbs"
    }
  });

  _exports.default = _default;
});