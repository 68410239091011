define("@fantasy-account/webapp/uploading/uploader", ["exports", "@playon/framework/uploading/uploader"], function (_exports, _uploader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "UPLOADER_DID_PROGRESS", {
    enumerable: true,
    get: function () {
      return _uploader.UPLOADER_DID_PROGRESS;
    }
  });
  Object.defineProperty(_exports, "UPLOADER_DID_START", {
    enumerable: true,
    get: function () {
      return _uploader.UPLOADER_DID_START;
    }
  });
  Object.defineProperty(_exports, "UPLOADER_DID_SUCCESS", {
    enumerable: true,
    get: function () {
      return _uploader.UPLOADER_DID_SUCCESS;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _uploader.default;
    }
  });
});