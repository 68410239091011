define("@fantasy-account/webapp/templates/components/application/hooks/before-app-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N7u+94F9",
    "block": "{\"symbols\":[],\"statements\":[[8,\"top-back-bar\",[],[[],[]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/application/hooks/before-app-navigation.hbs"
    }
  });

  _exports.default = _default;
});