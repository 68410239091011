define("@fantasy-account/webapp/templates/components/card/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TB2zi2Qa",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"card__title\"],[12],[2,\"\\n    \"],[1,[34,1]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"card__subtitle\"],[12],[2,\"\\n      \"],[1,[34,0]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[18,1,[[30,[36,4],null,[[\"Heading\",\"Button\",\"Link\"],[[30,[36,3],[\"medium-heading\"],[[\"tag\",\"nestedClasses\"],[\"h2\",\"card__header__heading\"]]],[30,[36,3],[\"button\"],[[\"kind\",\"nestedClasses\"],[\"text\",\"card__header__action card__header__action--button\"]]],[30,[36,3],[\"link\"],[[\"kind\",\"nestedClasses\"],[\"text\",\"card__header__action card__header__action--link\"]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"subtitle\",\"title\",\"if\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/card/header.hbs"
    }
  });

  _exports.default = _default;
});