define("@fantasy-account/webapp/decorator/math/index", ["exports", "@playon/framework/decorator/math"], function (_exports, _math) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "abs", {
    enumerable: true,
    get: function () {
      return _math.abs;
    }
  });
  Object.defineProperty(_exports, "ceil", {
    enumerable: true,
    get: function () {
      return _math.ceil;
    }
  });
  Object.defineProperty(_exports, "floor", {
    enumerable: true,
    get: function () {
      return _math.floor;
    }
  });
  Object.defineProperty(_exports, "numberSign", {
    enumerable: true,
    get: function () {
      return _math.numberSign;
    }
  });
  Object.defineProperty(_exports, "sum", {
    enumerable: true,
    get: function () {
      return _math.sum;
    }
  });
});