define("@fantasy-account/webapp/templates/account/deposit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6fmtV4Yj",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[30,[36,1],[\"quick-deposit\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"balance/wizard\",[],[[\"@type\",\"@summaryType\",\"@heading\"],[\"quick-deposit\",\"deposit\",[30,[36,0],[\"balance.deposit.heading.quick\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"balance/wizard\",[],[[\"@type\",\"@heading\"],[\"deposit\",[30,[36,0],[\"balance.deposit.heading.default\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"can\",\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/account/deposit.hbs"
    }
  });

  _exports.default = _default;
});