define("@fantasy-account/webapp/templates/components/uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xn6UBJ7F",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,4],[[32,0,[\"isDragAndDropSource\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,4],[[32,0,[\"uploading\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[14,0,\"uploader__uploading-label\"],[12],[1,[32,0,[\"uploadingLabel\"]]],[13],[2,\"\\n    \"],[18,1,[[30,[36,2],null,[[\"ProgressBar\"],[[30,[36,1],[\"progress-bar\"],[[\"theme\",\"progress\",\"label\"],[[32,0,[\"theme\"]],[32,0,[\"progress\"]],[32,0,[\"uploadingLabel\"]]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,4],[[32,0,[\"imagePreviewUrl\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n    \"],[10,\"img\"],[14,0,\"uploader__preview\"],[15,\"src\",[32,0,[\"imagePreviewUrl\"]]],[15,\"alt\",[32,0,[\"file\",\"name\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[18,1,[[30,[36,2],null,[[\"Icon\",\"Label\"],[[30,[36,1],[\"icon\"],[[\"class\"],[\"uploader__icon\"]]],[30,[36,1],[\"label-view\"],[[\"class\"],[\"uploader__label\"]]]]]]]],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,4],[[32,0,[\"uploading\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,[[30,[36,2],null,[[\"ProgressBar\"],[[30,[36,1],[\"progress-bar\"],[[\"theme\",\"progress\",\"label\"],[[32,0,[\"theme\"]],[32,0,[\"progress\"]],[35,3]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[18,1,[[30,[36,2],null,[[\"ContainedButton\"],[[30,[36,1],[\"contained-button\"],[[\"action\"],[[30,[36,0],[[32,0],\"chooseFile\"],null]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"component\",\"hash\",\"uploadingLabel\",\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/uploader.hbs"
    }
  });

  _exports.default = _default;
});