define("@fantasy-account/webapp/templates/components/payment-methods/default-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9q2h52sg",
    "block": "{\"symbols\":[\"Picker\",\"paymentMethod\",\"@selected\",\"@onChange\"],\"statements\":[[8,\"payment-methods/picker\",[],[[\"@selected\",\"@onChange\"],[[32,3],[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,0,[\"paymentMethods\"]]],[[\"use\",\"duration\"],[[32,0,[\"transition\"]],350]],[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,1,[\"PaymentMethod\"]],[],[[\"@paymentMethod\"],[[32,2]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"animated-each\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/payment-methods/default-picker.hbs"
    }
  });

  _exports.default = _default;
});