define("@fantasy-account/webapp/data/adapters/index", ["exports", "@fantasy-account/engine/data/adapters"], function (_exports, _adapters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "UserAdapter", {
    enumerable: true,
    get: function () {
      return _adapters.UserAdapter;
    }
  });
});