define("@fantasy-account/webapp/formatters/short-number", ["exports", "@playon/framework/formatters/short-number"], function (_exports, _shortNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _shortNumber.default;
    }
  });
});