define("@fantasy-account/webapp/controllers/account/transactions", ["exports", "@playon/framework/factory"], function (_exports, _factory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * @public
   *
   * @class AccountTransactionsController
   * @extends Controller
   *
   * @since 1.15.0
   */
  let AccountTransactionsController = (_dec = (0, _factory.inject)("transaction/sorters"), _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._action, (_class = class AccountTransactionsController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "sorters", _descriptor, this);

      _initializerDefineProperty(this, "device", _descriptor2, this);

      _initializerDefineProperty(this, "transactions", _descriptor3, this);
    }

    /**
     * @protected
     * @param {String} value
     * @param {Number} group
     *
     * @since 1.0.0
     */
    sortTransactions(value, group) {
      if (group === 1) {
        this.sorters.sortBy(value);
      } else {
        this.sorters.current.toggleDirection(value);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sorters", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "device", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "transactions", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [{
        date: 'Jun 13, 2019',
        concept: "Contest",
        description: "Contest name",
        paidBy: "Balance",
        amount: "-$22",
        balance: "$0"
      }, {
        date: 'Jun 15, 2019',
        concept: "Subscription",
        description: "Contest name",
        paidBy: "Balance",
        amount: "-$22",
        balance: "$0"
      }, {
        date: 'Jun 13, 2019',
        concept: "Concept",
        description: "Contest name",
        paidBy: "Balance",
        amount: "-$22",
        balance: "$0"
      }, {
        date: 'Jun 13, 2019',
        concept: "Concept",
        description: "Contest name",
        paidBy: "Balance",
        amount: "-$21",
        balance: "$10"
      }, {
        date: 'Jun 14, 2019',
        concept: "Concept",
        description: "Contest name",
        paidBy: "Balance",
        amount: "-$22",
        balance: "$0"
      }, {
        date: 'Jun 13, 2019',
        concept: "Concept",
        description: "Contest name",
        paidBy: "Balance",
        amount: "-$54",
        balance: "$50"
      }, {
        date: 'Jun 13, 2019',
        concept: "Concept",
        description: "Contest name",
        paidBy: "Balance",
        amount: "-$22",
        balance: "$0"
      }];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "sortTransactions", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "sortTransactions"), _class.prototype)), _class));
  _exports.default = AccountTransactionsController;
});