define("@fantasy-account/webapp/templates/components/region", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EZ+aZxEF",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],[[35,2]],[[\"layoutName\",\"context\",\"parent\"],[[35,1],[35,0],[32,0]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"context\",\"template\",\"componentName\",\"component\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/region.hbs"
    }
  });

  _exports.default = _default;
});