define("@fantasy-account/webapp/templates/components/login/form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QsI0sCGo",
    "block": "{\"symbols\":[\"Field\"],\"statements\":[[8,\"form\",[[24,0,\"form__login\"]],[[\"@delegate\"],[[32,0]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Text\"]],[[4,[38,1],null,[[\"delay\"],[0.5]]]],[[\"@theme\",\"@name\",\"@label\",\"@max\",\"@type\"],[\"fantasy-account\",\"email\",[30,[36,0],[\"auth.label.email\"],null],50,\"email\"]],null],[2,\"\\n  \"],[8,[32,1,[\"Text\"]],[],[[\"@theme\",\"@name\",\"@label\",\"@max\",\"@type\"],[\"fantasy-account\",\"password\",[30,[36,0],[\"auth.label.password\"],null],50,\"password\"]],null],[2,\"\\n\\n  \"],[8,\"recaptcha\",[],[[\"@onChange\",\"@onRender\"],[[32,0,[\"changeRecaptcha\"]],[32,0,[\"renderRecaptcha\"]]]],null],[2,\"\\n\\n  \"],[8,[32,1,[\"Checkbox\"]],[],[[\"@theme\",\"@name\",\"@label\",\"@modifier\"],[\"fantasy-account\",\"rememberMe\",[30,[36,0],[\"auth.label.keep-logged-in\"],null],\"center\"]],null],[2,\"\\n  \"],[8,[32,1,[\"Submit\"]],[],[[\"@theme\",\"@label\",\"@modifier\"],[\"fantasy-account\",[30,[36,0],[\"auth.button.login\"],null],\"flat block login\"]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"autofocus\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/login/form.hbs"
    }
  });

  _exports.default = _default;
});