define("@fantasy-account/webapp/repositories/ott/f1/season", ["exports", "@playon/framework/ott/services/f1/repositories/season"], function (_exports, _season) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _season.default;
    }
  });
});