define("@fantasy-account/webapp/templates/components/radio/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QVdtmBEh",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[8,\"icon\",[],[[\"@icon\"],[[32,0,[\"icon\"]]]],null],[2,\"\\n\\n\"],[6,[37,0],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[32,0,[\"label\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/radio/item.hbs"
    }
  });

  _exports.default = _default;
});