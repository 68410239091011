define("@fantasy-account/webapp/templates/components/filters/selection/option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TJsUkLq6",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[14,0,\"filters__filter__options__option__label\"],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"option\",\"labelKey\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],[[32,0,[\"option\",\"labelKey\"]]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[32,0,[\"option\",\"label\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[6,[37,1],[[32,0,[\"selected\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"icon\",[[24,0,\"filters__filter__options__option__icon\"]],[[\"@icon\",\"@theme\"],[\"check-square:fa:fas\",\"accent\"]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"icon\",[[24,0,\"filters__filter__options__option__icon\"]],[[\"@icon\",\"@theme\"],[\"square:fa:far\",\"secondary\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/filters/selection/option.hbs"
    }
  });

  _exports.default = _default;
});