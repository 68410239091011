define("@fantasy-account/webapp/templates/components/account/settings/payment-methods/form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IwS4HcrI",
    "block": "{\"symbols\":[\"Fields\",\"@isEditing\"],\"statements\":[[8,\"form\",[[24,0,\"form__card-details\"]],[[\"@fields\",\"@rules\",\"@target\"],[[32,0,[\"fields\"]],[32,0,[\"rules\"]],[32,0]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"TextField\"]],[[4,[38,1],null,[[\"delay\"],[0.5]]]],[[\"@theme\",\"@name\",\"@label\",\"@max\"],[\"fantasy-account\",\"holderName\",\"Name on Card\",50]],null],[2,\"\\n\\n  \"],[8,[32,1,[\"Checkbox\"]],[],[[\"@name\",\"@theme\"],[\"default\",\"fantasy-account\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"small\"],[14,0,\"text--md\"],[12],[2,\"\\n      Set as default payment method.\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"  \\n\\n  \"],[8,[32,1,[\"TextField\"]],[],[[\"@theme\",\"@name\",\"@label\",\"@max\"],[\"fantasy-account\",\"number\",\"Card number\",16]],null],[2,\"\\n  \"],[8,[32,1,[\"TextField\"]],[],[[\"@theme\",\"@name\",\"@label\",\"@max\"],[\"fantasy-account\",\"expirationDate\",\"Expircation date\",4]],null],[2,\"\\n  \"],[8,[32,1,[\"TextField\"]],[],[[\"@theme\",\"@name\",\"@label\",\"@max\"],[\"fantasy-account\",\"cvv\",\"CVV\",3]],null],[2,\"\\n\\n\"],[6,[37,2],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,1,[\"Button\"]],[[4,[38,0],[\"click\",[32,0,[\"removeCard\"]]],null]],[[\"@label\",\"@theme\",\"@modifier\"],[\"Remove\",\"fantasy-account\",\"flat md\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[8,[32,1,[\"Submit\"]],[],[[\"@label\",\"@theme\",\"@modifier\"],[[32,0,[\"buttonActionLabel\"]],\"fantasy-account\",\"flat md\"]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"autofocus\",\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/account/settings/payment-methods/form.hbs"
    }
  });

  _exports.default = _default;
});