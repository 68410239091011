define("@fantasy-account/webapp/templates/components/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PMuUKegV",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"Link\",\"Row\",\"Item\",\"Anchor\",\"Separator\",\"EditableItem\",\"Header\",\"TextFieldItem\",\"RadioItem\",\"skeleton-item\",\"link\",\"row\",\"item\",\"separator\",\"text-field-item\",\"stepper-item\",\"slider-item\",\"tab-bar-item\",\"circular-progress-item\",\"button-item\",\"jumbo-item\",\"jumbo-row\"],[[30,[36,0],[\"list-link\"],null],[30,[36,0],[\"list-row\"],null],[30,[36,0],[\"list-row\"],null],[30,[36,0],[\"list-anchor\"],null],[30,[36,0],[\"list-rows/separator\"],null],[30,[36,0],[\"list-item-editable\"],null],[30,[36,0],[\"list-row\"],[[\"nestedModifiers\"],[\"header\"]]],[30,[36,0],[\"list-rows/control\"],[[\"control\"],[\"text-field\"]]],[30,[36,0],[\"list-rows/control\"],[[\"control\"],[\"radio\"]]],[30,[36,0],[\"list-rows/skeleton\"],null],[30,[36,0],[\"list-link\"],null],[30,[36,0],[\"list-row\"],null],[30,[36,0],[\"list-row\"],null],[30,[36,0],[\"list-rows/separator\"],null],[30,[36,0],[\"list-rows/text-field\"],null],[30,[36,0],[\"list-rows/stepper\"],null],[30,[36,0],[\"list-rows/control\"],[[\"control\"],[\"slider\"]]],[30,[36,0],[\"list-rows/control\"],[[\"control\"],[\"tab-bar\"]]],[30,[36,0],[\"list-rows/control\"],[[\"control\"],[\"circular-progress\"]]],[30,[36,0],[\"list-rows/control\"],[[\"control\"],[\"button\"]]],[30,[36,0],[\"list-jumbo-row\"],null],[30,[36,0],[\"list-jumbo-row\"],null]]]]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/list.hbs"
    }
  });

  _exports.default = _default;
});