define("@fantasy-account/webapp/routes/application", ["exports", "@playon/framework/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @public
   *
   * @class ApplicationRoute
   * @extends Route
   *
   * @since 1.16.0
   */
  class ApplicationRoute extends Ember.Route.extend(_application.AppLoader) {}

  _exports.default = ApplicationRoute;
});