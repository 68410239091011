define("@fantasy-account/webapp/validation/index", ["exports", "@playon/framework/validation"], function (_exports, _validation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "MessageBag", {
    enumerable: true,
    get: function () {
      return _validation.MessageBag;
    }
  });
  Object.defineProperty(_exports, "TypableValidationRule", {
    enumerable: true,
    get: function () {
      return _validation.TypableValidationRule;
    }
  });
  Object.defineProperty(_exports, "ValidationRule", {
    enumerable: true,
    get: function () {
      return _validation.ValidationRule;
    }
  });
  Object.defineProperty(_exports, "inject", {
    enumerable: true,
    get: function () {
      return _validation.inject;
    }
  });
});