define("@fantasy-account/webapp/templates/components/bar-button-anchor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KTlOJ04D",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[32,0,[\"iconIsPreferred\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,2],null,[[\"href\",\"target\",\"icon\",\"theme\",\"modifier\"],[[32,0,[\"href\"]],[32,0,[\"target\"]],[32,0,[\"icon\"]],[32,0,[\"theme\"]],[32,0,[\"modifier\"]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,1],null,[[\"href\",\"target\",\"label\",\"icon\",\"theme\",\"modifier\"],[[32,0,[\"href\"]],[32,0,[\"target\"]],[32,0,[\"label\"]],[32,0,[\"icon\"]],[35,0],[32,0,[\"modifier\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],[[32,0,[\"badge\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"badge\",[[24,0,\"app-bar__item__badge\"]],[[\"@text\",\"@shape\"],[[32,0,[\"badgeContent\"]],[32,0,[\"badgeShape\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"theme\",\"text-anchor\",\"icon-anchor\",\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/bar-button-anchor.hbs"
    }
  });

  _exports.default = _default;
});