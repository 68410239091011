define("@fantasy-account/webapp/templates/components/navigation-drawer/menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MnzElq1R",
    "block": "{\"symbols\":[\"&attrs\",\"@icon\",\"@label\",\"@navigationDrawer\",\"&default\"],\"statements\":[[11,\"ul\"],[16,0,[32,0,[\"classes\"]]],[17,1],[12],[2,\"\\n  \"],[18,5,[[30,[36,1],null,[[\"Item\",\"LineDivider\",\"Space\",\"SpaceFlexible\"],[[30,[36,0],[\"navigation-drawer/menu/item\"],[[\"navigationDrawer\",\"label\",\"icon\",\"tooltip\"],[[32,4],[32,3],[32,2],[32,0,[\"tooltip\"]]]]],[30,[36,0],[\"navigation-drawer/menu/item\"],[[\"modifier\"],[\"line-divider\"]]],[30,[36,0],[\"navigation-drawer/menu/item\"],[[\"modifier\"],[\"space\"]]],[30,[36,0],[\"navigation-drawer/menu/item\"],[[\"modifier\"],[\"space-flexible\"]]]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/navigation-drawer/menu.hbs"
    }
  });

  _exports.default = _default;
});