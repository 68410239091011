define("@fantasy-account/webapp/components/top-back-bar", ["exports", "@fantasy-account/engine/components/top-back-bar"], function (_exports, _topBackBar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _topBackBar.default;
    }
  });
});