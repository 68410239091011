define("@fantasy-account/webapp/components/user/balance", ["exports", "@fantasy-account/engine/components/user/balance"], function (_exports, _balance) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _balance.default;
    }
  });
});