define("@fantasy-account/webapp/templates/components/filters/selection-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ui1dLwNF",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[8,\"filters/label\",[],[[\"@label\",\"@labelKey\",\"@hasLabel\"],[[32,0,[\"filter\",\"label\"]],[32,0,[\"filter\",\"labelKey\"]],[32,0,[\"filter\",\"hasLabel\"]]]],null],[2,\"\\n\\n\"],[10,\"ul\"],[14,0,\"filters__filter__options\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"options\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],[[32,0,[\"filter\",\"optionComponent\"]]],[[\"option\",\"selection\",\"action\"],[[32,1],[32,0,[\"selection\"]],[30,[36,0],[[32,0],\"selectItem\",[32,1]],null]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"component\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/filters/selection-filter.hbs"
    }
  });

  _exports.default = _default;
});