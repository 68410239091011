define("@fantasy-account/webapp/templates/components/app-bar/top/account/transactions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rAi46vAo",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[32,0,[\"device\",\"type\",\"mobile\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[30,[36,0],[[32,0,[\"app-bar\",\"menu-item\"]],\"expected `this.app-bar.menu-item` to be a contextual component but found a string. Did you mean `(component this.app-bar.menu-item)`? ('@fantasy-account/webapp/templates/components/app-bar/top/account/transactions.hbs' @ L2:C4) \"],null]],[[\"theme\"],[\"muted\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[30,[36,1],[[30,[36,0],[[32,0,[\"app-bar\",\"heading\"]],\"expected `this.app-bar.heading` to be a contextual component but found a string. Did you mean `(component this.app-bar.heading)`? ('@fantasy-account/webapp/templates/components/app-bar/top/account/transactions.hbs' @ L5:C2) \"],null]],[[\"title\"],[[30,[36,3],[\"navigation.heading.transactions\"],null]]]]],[2,\"\\n\\n\"],[6,[37,2],[[35,4,[\"type\",\"mobile\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[30,[36,0],[[32,0,[\"app-bar\",\"button-item\"]],\"expected `this.app-bar.button-item` to be a contextual component but found a string. Did you mean `(component this.app-bar.button-item)`? ('@fantasy-account/webapp/templates/components/app-bar/top/account/transactions.hbs' @ L8:C4) \"],null]],[[\"icon\",\"menu\",\"class\"],[\"filter:fa:far\",\"transactions-filter\",\"account__transactions__heading__filter\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-assert-implicit-component-helper-argument\",\"component\",\"if\",\"t\",\"device\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/app-bar/top/account/transactions.hbs"
    }
  });

  _exports.default = _default;
});