define("@fantasy-account/webapp/templates/components/top-back-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3Lu9otDR",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[8,\"animated-container\",[[16,0,[31,[[32,0,[\"className\"]],\"-container\"]]]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[32,0,[\"fantasyAccount\",\"presented\"]]],[[\"rules\",\"duration\"],[[32,0,[\"rules\"]],350]],[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"link-to\",[[16,0,[32,0,[\"className\"]]],[17,1]],[[\"@route\",\"@dismiss\"],[[32,0,[\"fantasyAccount\",\"backRoute\"]],true]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"icon\",[[16,0,[31,[[32,0,[\"className\"]],\"__icon\"]]]],[[\"@icon\"],[\"long-arrow-left:fa:far\"]],null],[2,\"\\n      \"],[1,[30,[36,0],[\"navigation.return-to-app\"],null]],[2,\"\\n      \"],[8,\"logo\",[[16,0,[31,[[32,0,[\"className\"]],\"__logo\"]]]],[[\"@kind\",\"@alt\"],[[32,0,[\"playon\",\"name\"]],[31,[[32,0,[\"playon\",\"name\"]],\" Logo\"]]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"animated-if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/top-back-bar.hbs"
    }
  });

  _exports.default = _default;
});