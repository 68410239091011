define("@fantasy-account/webapp/templates/components/account/profile/username", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0EAuCsiE",
    "block": "{\"symbols\":[\"@onClick\"],\"statements\":[[10,\"div\"],[14,0,\"my__profile__username\"],[12],[2,\"\\n\"],[2,\"  Username \"],[8,\"icon-button\",[],[[\"@icon\",\"@action\"],[\"pen:fa:fal\",[32,1]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/account/profile/username.hbs"
    }
  });

  _exports.default = _default;
});