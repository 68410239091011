define("@fantasy-account/webapp/templates/components/wizard/stepper/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4npPu/fR",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"li\"],[16,0,[32,0,[\"classes\"]]],[17,1],[4,[38,0],[\"click\",[32,0,[\"click\"]]],null],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/wizard/stepper/item.hbs"
    }
  });

  _exports.default = _default;
});