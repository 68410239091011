define("@fantasy-account/webapp/templates/components/progress-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZUnWc7dh",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"label\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"progress-bar__label\"],[12],[2,\"\\n    \"],[1,[32,0,[\"label\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[15,0,[31,[\"progress-bar__track \",[34,1]]]],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"progress-bar__indicator \",[34,2]]]],[15,5,[34,3]],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"trackClass\",\"indicatorClass\",\"trackStyle\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/progress-bar.hbs"
    }
  });

  _exports.default = _default;
});