define("@fantasy-account/webapp/templates/components/accordion-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wwx8EeN0",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[32,0,[\"classes\"]]],[17,1],[4,[38,0],[[32,0,[\"didInsert\"]]],null],[12],[2,\"\\n  \"],[18,2,[[30,[36,3],null,[[\"toggle\",\"Header\",\"Body\",\"Footer\"],[[30,[36,2],[[32,0],[32,0,[\"toggle\"]]],null],[30,[36,1],[\"accordion-panel/header\"],[[\"onClick\"],[[30,[36,2],[[32,0],[32,0,[\"toggle\"]]],null]]]],[30,[36,1],[\"accordion-panel/body\"],[[\"visible\",\"transitionDuration\"],[[32,0,[\"expanded\"]],[32,0,[\"transitionDuration\"]]]]],[30,[36,1],[\"accordion-panel/footer\"],null]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"component\",\"action\",\"hash\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/accordion-panel.hbs"
    }
  });

  _exports.default = _default;
});