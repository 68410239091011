define("@fantasy-account/webapp/templates/components/data-table-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SFKTYTYS",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"data-table__cell__content\"],[12],[2,\"\\n\"],[6,[37,3],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[34,2]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],[[35,0]],[[\"class\"],[\"data-table__cell__icon\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"icon-view\",\"data\",\"if\",\"sortable\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/data-table-cell.hbs"
    }
  });

  _exports.default = _default;
});