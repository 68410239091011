define("@fantasy-account/webapp/templates/components/navigation-drawer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nqeMaKCa",
    "block": "{\"symbols\":[\"componentName\",\"&default\",\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[32,0,[\"classes\"]]],[17,3],[4,[38,4],[[32,0,[\"didInsert\"]]],null],[4,[38,5],[\"click\",[32,0,[\"click\"]]],null],[12],[2,\"\\n  \"],[10,\"aside\"],[14,0,\"navigation-drawer__frame\"],[12],[2,\"\\n    \"],[8,\"icon-button\",[[24,0,\"navigation-drawer__shrink-button\"]],[[\"@icon\",\"@modifier\",\"@action\"],[\"chevron-left:fa:fas\",\"circle\",[32,0,[\"shrink\"]]]],null],[2,\"\\n\\n\"],[6,[37,2],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,2,[[30,[36,3],null,[[\"Inner\"],[[30,[36,0],[[32,0,[\"component\"]]],[[\"navigationDrawer\",\"navigationDrawerName\",\"service\"],[[32,0],[32,0,[\"name\"]],[32,0,[\"service\"]]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[32,0,[\"component\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,0,[\"component\"]]],[[\"use\",\"duration\"],[[32,0,[\"transition\"]],250]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],[[32,1]],[[\"navigationDrawer\",\"navigationDrawerName\",\"service\"],[[32,0],[32,0,[\"name\"]],[32,0,[\"service\"]]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n  \"],[11,\"div\"],[24,0,\"navigation-drawer__overlay\"],[4,[38,5],[\"click\",[32,0,[\"shrink\"]]],null],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"animated-value\",\"if\",\"hash\",\"did-insert\",\"on\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/navigation-drawer.hbs"
    }
  });

  _exports.default = _default;
});