define("@fantasy-account/webapp/components/app-bar/top/web", ["exports", "@playon/framework/components/app-bar-content"], function (_exports, _appBarContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _appBarContent.default.extend({
    /**
     * @protected
     * @type {FantasyAccountService}
     *
     * @since 1.14.0
     */
    fantasyAccount: Ember.inject.service(),

    /**
     * @protected
     * @type {String}
     *
     * @since 1.14.0
     */
    theme: "fantasy-account"
  });

  _exports.default = _default;
});