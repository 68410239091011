define("@fantasy-account/webapp/controllers/account/my-profile", ["exports", "@fantasy-account/engine/controllers/account/my-profile"], function (_exports, _myProfile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _myProfile.default;
    }
  });
});