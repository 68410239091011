define("@fantasy-account/webapp/templates/components/modal/aside/list-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xJxeFVzD",
    "block": "{\"symbols\":[\"&default\",\"@value\",\"@icon\",\"@label\"],\"statements\":[[10,\"li\"],[14,0,\"modal__aside__list__item\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"modal__aside__list__item__background\"],[12],[13],[2,\"\\n  \"],[8,\"icon\",[[24,0,\"modal__aside__list__item__icon\"]],[[\"@icon\"],[[32,3]]],null],[2,\"\\n  \"],[10,\"span\"],[14,0,\"modal__aside__list__item__label\"],[12],[1,[32,4]],[13],[2,\"\\n  \"],[10,\"span\"],[14,0,\"modal__aside__list__item__value\"],[12],[2,\"\\n\"],[6,[37,0],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[32,2]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/modal/aside/list-row.hbs"
    }
  });

  _exports.default = _default;
});