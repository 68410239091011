define("@fantasy-account/webapp/templates/components/radio-indicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QUKx7BNg",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[32,0,[\"classes\"]]],[17,1],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[[32,0,[\"className\"]],\"__inner\"]]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/radio-indicator.hbs"
    }
  });

  _exports.default = _default;
});