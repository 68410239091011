define("@fantasy-account/webapp/templates/components/verification/document/wizard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/V6Miatb",
    "block": "{\"symbols\":[],\"statements\":[[8,\"fantasy-account/wizard/stepper\",[[16,0,[32,0,[\"className\"]]]],[[\"@wizard\"],[[32,0,[\"verification\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/verification/document/wizard.hbs"
    }
  });

  _exports.default = _default;
});