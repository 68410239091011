define("@fantasy-account/webapp/mixins/fetchable", ["exports", "@playon/framework/mixins/fetchable"], function (_exports, _fetchable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _fetchable.default;
    }
  });
});