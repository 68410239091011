define("@fantasy-account/webapp/templates/components/fantasy-account/wizard/stepper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BvzUW/3g",
    "block": "{\"symbols\":[\"step\",\"@onChange\",\"&attrs\"],\"statements\":[[11,\"ul\"],[16,0,[32,0,[\"classes\"]]],[17,3],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"wizard\",\"steps\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,1,[\"hidden\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"fantasy-account/wizard/stepper/item\",[],[[\"@step\",\"@onChange\"],[[32,1],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"unless\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/fantasy-account/wizard/stepper.hbs"
    }
  });

  _exports.default = _default;
});