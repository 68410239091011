define("@fantasy-account/webapp/components/navigation-drawer/menu/route", ["exports", "@playon/framework/components/navigation-drawer/menu/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _route.default;
    }
  });
});