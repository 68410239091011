define("@fantasy-account/webapp/templates/components/withdraw/wizard/amount", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Dso2l+9o",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[17,1],[12],[2,\"\\n  CARD!!!\\n  \"],[8,\"contained-button\",[],[[\"@action\"],[[32,0,[\"next\"]]]],[[\"default\"],[{\"statements\":[[2,\"Next\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/withdraw/wizard/amount.hbs"
    }
  });

  _exports.default = _default;
});