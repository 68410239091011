define("@fantasy-account/webapp/components/label", ["exports", "@playon/framework/components/label-view"], function (_exports, _labelView) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _labelView.default;
    }
  });
});