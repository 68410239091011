define("@fantasy-account/webapp/router", ["exports", "@playon/framework/routing", "@fantasy-account/webapp/config/environment"], function (_exports, _routing, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Router extends Ember.Router {
    constructor() {
      super(...arguments);

      _defineProperty(this, "location", _environment.default.locationType);

      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;
  Router.map(function () {
    (0, _routing.mount)(this, _environment.default.FANTASY_ACCOUNT.router.routes);
    this.route("reset-password");
    this.route('web', {
      path: "/"
    }, function () {
      this.route("privacy-policy");
      this.route("terms-and-conditions");
    });
    this.route("404", {
      path: "/*path"
    });
  });
});