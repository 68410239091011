define("@fantasy-account/webapp/templates/web/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Eey+VI/m",
    "block": "{\"symbols\":[],\"statements\":[[8,\"homepage\",[],[[],[]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/web/index.hbs"
    }
  });

  _exports.default = _default;
});