define("@fantasy-account/webapp/templates/components/navigation-drawer/menu/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EbpR+CpX",
    "block": "{\"symbols\":[\"&attrs\",\"@rightIcon\",\"@icon\",\"@label\",\"&default\"],\"statements\":[[11,\"li\"],[16,0,[32,0,[\"classes\"]]],[17,1],[4,[38,0],[[32,0,[\"tooltip\"]]],[[\"placement\",\"arrow\",\"distance\",\"skip\"],[\"right\",false,20,[32,0,[\"whenDrawerExpanded\"]]]]],[12],[2,\"\\n  \"],[18,5,[[30,[36,2],null,[[\"Action\",\"Link\",\"Route\"],[[30,[36,1],[\"navigation-drawer/menu/action\"],[[\"label\",\"icon\",\"rightIcon\",\"tooltip\"],[[32,4],[32,3],[32,2],[32,0,[\"tooltip\"]]]]],[30,[36,1],[\"navigation-drawer/menu/link\"],[[\"label\",\"icon\",\"rightIcon\",\"tooltip\"],[[32,4],[32,3],[32,2],[32,0,[\"tooltip\"]]]]],[30,[36,1],[\"navigation-drawer/menu/route\"],[[\"label\",\"icon\",\"rightIcon\",\"tooltip\"],[[32,4],[32,3],[32,2],[32,0,[\"tooltip\"]]]]]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"tooltip\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/navigation-drawer/menu/item.hbs"
    }
  });

  _exports.default = _default;
});