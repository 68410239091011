define("@fantasy-account/webapp/templates/account/settings/payment-methods/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RbgNI4nq",
    "block": "{\"symbols\":[],\"statements\":[[8,\"account/settings/payment-methods/method\",[],[[\"@isDefaultMethod\"],[true]],null],[2,\"\\n\"],[8,\"account/settings/payment-methods/method\",[],[[],[]],null],[2,\"\\n\"],[8,\"account/settings/payment-methods/method\",[],[[\"@isNewMethod\",\"@isNewMethodSelected\",\"@onNewMethod\"],[true,[32,0,[\"isNewMethodSelected\"]],[30,[36,0],[[32,0],\"newMethod\"],null]]],null],[2,\"\\n\\n\"],[6,[37,1],[[32,0,[\"isNewMethodSelected\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t\"],[8,\"account/settings/payment-methods/form\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/account/settings/payment-methods/index.hbs"
    }
  });

  _exports.default = _default;
});