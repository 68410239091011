define("@fantasy-account/webapp/computed/math/index", ["exports", "@playon/framework/computed/math"], function (_exports, _math) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "abs", {
    enumerable: true,
    get: function () {
      return _math.abs;
    }
  });
  Object.defineProperty(_exports, "ceil", {
    enumerable: true,
    get: function () {
      return _math.ceil;
    }
  });
});