define("@fantasy-account/webapp/templates/components/account/transactions/row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7kYsoQTq",
    "block": "{\"symbols\":[\"@transaction\"],\"statements\":[[10,\"div\"],[14,0,\"account__transactions__list__row\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"account__transactions__list__row__col\"],[12],[1,[32,1,[\"date\"]]],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"account__transactions__list__row__col\"],[12],[1,[32,1,[\"concept\"]]],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"account__transactions__list__row__col\"],[12],[1,[32,1,[\"description\"]]],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"account__transactions__list__row__col\"],[12],[1,[32,1,[\"paidBy\"]]],[13],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"account__transactions__list__row__col\"],[12],[1,[32,1,[\"amount\"]]],[13],[2,\"\\t\\n\\t\"],[10,\"div\"],[14,0,\"account__transactions__list__row__col\"],[12],[1,[32,1,[\"balance\"]]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/account/transactions/row.hbs"
    }
  });

  _exports.default = _default;
});