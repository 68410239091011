define("@fantasy-account/webapp/trait/model/index", ["exports", "@playon/framework/trait/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "authorizable", {
    enumerable: true,
    get: function () {
      return _model.authorizable;
    }
  });
  Object.defineProperty(_exports, "deletable", {
    enumerable: true,
    get: function () {
      return _model.deletable;
    }
  });
});