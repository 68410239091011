define("@fantasy-account/webapp/decorator/string/index", ["exports", "@playon/framework/decorator/string"], function (_exports, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "dasherize", {
    enumerable: true,
    get: function () {
      return _string.dasherize;
    }
  });
  Object.defineProperty(_exports, "endsWith", {
    enumerable: true,
    get: function () {
      return _string.endsWith;
    }
  });
  Object.defineProperty(_exports, "interpolate", {
    enumerable: true,
    get: function () {
      return _string.interpolate;
    }
  });
  Object.defineProperty(_exports, "lowercase", {
    enumerable: true,
    get: function () {
      return _string.lowercase;
    }
  });
  Object.defineProperty(_exports, "safeString", {
    enumerable: true,
    get: function () {
      return _string.safeString;
    }
  });
});