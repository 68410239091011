define("@fantasy-account/webapp/templates/components/video-frame", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iEB9qUln",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"video-frame__message\"],[12],[2,\"\\n    \"],[8,\"icon-view\",[],[[\"@icon\"],[\"lock:fa:fas\"]],null],[2,\"\\n    \"],[10,\"span\"],[12],[2,\"You are not allowed to see this content\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"iframe\"],[15,\"allowfullscreen\",[32,0,[\"allowFullScreenAttribute\"]]],[15,\"src\",[32,0,[\"src\"]]],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"disabled\",\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/video-frame.hbs"
    }
  });

  _exports.default = _default;
});