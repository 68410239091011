define("@fantasy-account/webapp/templates/components/image/picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j1QsA+fC",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[32,0,[\"classes\"]]],[16,5,[32,0,[\"css\"]]],[17,1],[4,[38,0],[[32,0,[\"modal\"]]],null],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"image-picker__overlay\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"image-picker__overlay__content\"],[12],[2,\"\\n      \"],[8,\"icon\",[[24,0,\"image-picker__overlay__icon\"]],[[\"@icon\"],[[32,0,[\"icon\"]]]],null],[2,\"\\n      \"],[10,\"span\"],[14,0,\"image-picker__overlay__label\"],[12],[1,[30,[36,1],[[32,0,[\"label\"]]],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"modal\",\"t\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/image/picker.hbs"
    }
  });

  _exports.default = _default;
});