define("@fantasy-account/webapp/components/tab-content", ["exports", "@playon/framework/components/tab-content"], function (_exports, _tabContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _tabContent.default;
    }
  });
});