define("@fantasy-account/webapp/templates/components/app-bar/heading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U8rzN0A1",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[8,\"regular-heading\",[[24,0,\"app-bar__heading__title\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[32,0,[\"title\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,2],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"label-view\",[[24,0,\"app-bar__heading__subtitle\"]],[[\"@icon\"],[[34,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"badge-view\",[[24,0,\"app-bar__heading__badge\"]],[[\"@text\",\"@shape\"],[[34,0],\"circle\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[1,[34,3]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"badge\",\"icon\",\"if\",\"subtitle\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/app-bar/heading.hbs"
    }
  });

  _exports.default = _default;
});