define("@fantasy-account/webapp/templates/account/settings/deposit-limit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MQsLRM18",
    "block": "{\"symbols\":[\"Control\"],\"statements\":[[10,\"div\"],[14,0,\"account__settings__deposit-limit\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"account__settings__deposit-limit__info\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"account__settings__deposit-limit__info__box\"],[12],[2,\"\\n      \"],[8,\"bold-heading\",[[24,0,\"account__settings__deposit-limit__title\"]],[[\"@tag\",\"@title\"],[\"h2\",[30,[36,0],[\"account.settings.deposit-limit.title\"],null]]],null],[2,\"\\n\\n      \"],[10,\"p\"],[14,0,\"account__settings__deposit-limit__text\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"account.settings.deposit-limit.message\"],[[\"htmlSafe\"],[true]]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"account__settings__deposit-limit__info__box\"],[12],[2,\"\\n      \"],[8,\"select-control\",[[24,0,\"account__settings__deposit-limit__dropdown\"]],[[\"@label\",\"@selected\",\"@modifier\",\"@icon\",\"@onChange\"],[[32,0,[\"selectedDepositLimitLabel\"]],[32,0,[\"selectedDepositLimit\"]],\"xs dark uppercase\",\"caret-down:fa:fas\",[32,0,[\"changeDepositLimit\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"Option\"]],[],[[\"@label\",\"@value\"],[\"$1000\",1000]],null],[2,\"\\n        \"],[8,[32,1,[\"Option\"]],[],[[\"@label\",\"@value\"],[\"$2000\",2000]],null],[2,\"\\n        \"],[8,[32,1,[\"Option\"]],[],[[\"@label\",\"@value\"],[\"$3000\",3000]],null],[2,\"\\n        \"],[8,[32,1,[\"Option\"]],[],[[\"@label\",\"@value\"],[\"$4000\",4000]],null],[2,\"\\n      \"]],\"parameters\":[1]}]]],[2,\"\\t\\t\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\\t\"],[8,\"contained-button\",[[24,0,\"account__settings__deposit-limit__action\"],[4,[38,1],[\"click\",[32,0,[\"save\"]]],null]],[[\"@label\",\"@theme\",\"@modifier\"],[[30,[36,0],[\"account.settings.deposit-limit.button\"],null],\"fantasy-account\",\"flat\"]],null],[2,\"\\t\\n\"],[13],[2,\" \\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"on\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/account/settings/deposit-limit.hbs"
    }
  });

  _exports.default = _default;
});