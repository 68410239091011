define("@fantasy-account/webapp/models/payment-method/card", ["exports", "@fantasy-account/engine/data/models/payment-method/card"], function (_exports, _card) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _card.default;
    }
  });
});