define("@fantasy-account/webapp/components/payment-method/card/deposit", ["exports", "@fantasy-account/engine/components/payment-method/card/deposit"], function (_exports, _deposit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _deposit.default;
    }
  });
});