define("@fantasy-account/webapp/templates/components/list-rows/skeleton", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z4sJMlhN",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],[[35,0]],[[\"tagName\",\"skeleton\"],[\"\",[30,[36,1],null,[[\"text-line\",\"image\"],[[30,[36,0],[\"skeleton/text-line\"],null],[30,[36,0],[\"skeleton/image\"],null]]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[18,1,[[30,[36,1],null,[[\"text-line\",\"image\"],[[30,[36,0],[\"skeleton/text-line\"],null],[30,[36,0],[\"skeleton/image\"],null]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/list-rows/skeleton.hbs"
    }
  });

  _exports.default = _default;
});