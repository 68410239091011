define("@fantasy-account/webapp/templates/components/payment-method/picker-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5J2LpnAA",
    "block": "{\"symbols\":[\"&default\",\"&attrs\"],\"statements\":[[11,\"li\"],[16,0,[32,0,[\"classes\"]]],[17,2],[4,[38,1],[\"click\",[32,0,[\"click\"]]],null],[12],[2,\"\\n  \"],[8,\"radio-indicator\",[[16,0,[31,[[32,0,[\"className\"]],\"__radio-indicator\"]]]],[[],[]],null],[2,\"\\n\\n\"],[6,[37,2],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,[[30,[36,0],[[32,0,[\"innerComponent\"]]],[[\"paymentMethod\",\"className\"],[[32,0,[\"paymentMethod\"]],[32,0,[\"className\"]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,0],[[32,0,[\"innerComponent\"]]],[[\"paymentMethod\",\"className\"],[[32,0,[\"paymentMethod\"]],[32,0,[\"className\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"animated-container\",[[16,0,[31,[[32,0,[\"className\"]],\"__animator\"]]]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[32,0,[\"presentActionButton\"]]],[[\"use\",\"duration\"],[[32,0,[\"fade\"]],200]],[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"text-button\",[[16,0,[31,[[32,0,[\"className\"]],\"__action\"]]]],[[\"@label\",\"@modifier\"],[[32,0,[\"actionButtonLabel\"]],\"xs muted uppercase\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"on\",\"if\",\"animated-if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/payment-method/picker-item.hbs"
    }
  });

  _exports.default = _default;
});