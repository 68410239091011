define("@fantasy-account/webapp/computed/array/index", ["exports", "@playon/framework/computed/array"], function (_exports, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "filterBy", {
    enumerable: true,
    get: function () {
      return _array.filterBy;
    }
  });
  Object.defineProperty(_exports, "findBy", {
    enumerable: true,
    get: function () {
      return _array.findBy;
    }
  });
  Object.defineProperty(_exports, "rejectBy", {
    enumerable: true,
    get: function () {
      return _array.rejectBy;
    }
  });
});