define("@fantasy-account/webapp/decorator/index", ["exports", "@playon/framework/decorator"], function (_exports, _decorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "arg", {
    enumerable: true,
    get: function () {
      return _decorator.arg;
    }
  });
  Object.defineProperty(_exports, "argument", {
    enumerable: true,
    get: function () {
      return _decorator.argument;
    }
  });
  Object.defineProperty(_exports, "cached", {
    enumerable: true,
    get: function () {
      return _decorator.cached;
    }
  });
  Object.defineProperty(_exports, "component", {
    enumerable: true,
    get: function () {
      return _decorator.component;
    }
  });
  Object.defineProperty(_exports, "conditional", {
    enumerable: true,
    get: function () {
      return _decorator.conditional;
    }
  });
  Object.defineProperty(_exports, "config", {
    enumerable: true,
    get: function () {
      return _decorator.config;
    }
  });
  Object.defineProperty(_exports, "eq", {
    enumerable: true,
    get: function () {
      return _decorator.eq;
    }
  });
  Object.defineProperty(_exports, "equal", {
    enumerable: true,
    get: function () {
      return _decorator.equal;
    }
  });
  Object.defineProperty(_exports, "option", {
    enumerable: true,
    get: function () {
      return _decorator.option;
    }
  });
  Object.defineProperty(_exports, "param", {
    enumerable: true,
    get: function () {
      return _decorator.param;
    }
  });
  Object.defineProperty(_exports, "parameter", {
    enumerable: true,
    get: function () {
      return _decorator.parameter;
    }
  });
  Object.defineProperty(_exports, "raw", {
    enumerable: true,
    get: function () {
      return _decorator.raw;
    }
  });
  Object.defineProperty(_exports, "readOnly", {
    enumerable: true,
    get: function () {
      return _decorator.readOnly;
    }
  });
  Object.defineProperty(_exports, "supports", {
    enumerable: true,
    get: function () {
      return _decorator.supports;
    }
  });
  Object.defineProperty(_exports, "template", {
    enumerable: true,
    get: function () {
      return _decorator.template;
    }
  });
  Object.defineProperty(_exports, "value", {
    enumerable: true,
    get: function () {
      return _decorator.value;
    }
  });
});