define("@fantasy-account/webapp/templates/components/card/footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M1QBAGCz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"Button\"],[[30,[36,0],[\"button\"],[[\"kind\",\"nestedClasses\"],[\"text\",\"card__header__action card__header__action--button\"]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/card/footer.hbs"
    }
  });

  _exports.default = _default;
});