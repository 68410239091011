define("@fantasy-account/webapp/templates/components/account/suspend", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pMKlR8KK",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[32,0,[\"className\"]]],[17,1],[12],[2,\"\\n  \"],[8,\"account/panel\",[],[[\"@type\",\"@selectorOptions\",\"@selectorValue\",\"@selectorLabel\",\"@buttonDisabled\",\"@onChange\",\"@onSubmit\"],[\"suspend\",[32,0,[\"selectorOptions\"]],[32,0,[\"selectorValue\"]],[32,0,[\"selectorLabel\"]],[32,0,[\"buttonDisabled\"]],[32,0,[\"changeSelection\"]],[32,0,[\"submit\"]]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/account/suspend.hbs"
    }
  });

  _exports.default = _default;
});