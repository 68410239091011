define("@fantasy-account/webapp/templates/components/tab-bar-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IMofe4qa",
    "block": "{\"symbols\":[\"&default\",\"&attrs\"],\"statements\":[[11,\"li\"],[16,0,[32,0,[\"classes\"]]],[17,2],[4,[38,6],[[32,0]],null],[4,[38,7],[\"click\",[32,0,[\"click\"]]],null],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"tab-bar__item__link\"],[12],[2,\"\\n\"],[6,[37,1],[[27,[32,1]]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[14,0,\"tab-bar__item__content tab-bar__item__content--before\"],[12],[2,\"\\n          \"],[18,1,null],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,4],null,[[\"class\",\"icon\",\"isClassIconType\"],[\"tab-bar__item__icon\",[35,3],[35,2]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[32,0,[\"label\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"tab-bar__item__label\"],[12],[2,\"\\n        \"],[1,[32,0,[\"label\"]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[27,[32,1]]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[14,0,\"tab-bar__item__content tab-bar__item__content--after\"],[12],[2,\"\\n          \"],[18,1,null],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hasAfterBlock\",\"if\",\"isClassIconType\",\"icon\",\"icon-view\",\"hasBeforeBlock\",\"did-insert\",\"on\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/tab-bar-item.hbs"
    }
  });

  _exports.default = _default;
});