define("@fantasy-account/webapp/components/icon-link", ["exports", "@playon/framework/components/link"], function (_exports, _link) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _link.default.extend({
    /**
     * @public
     * @since 1.0.0
     * @type {String}
     */
    type: "icon",

    /**
     * @deprecated Don't use positional params.
     *
     * @private
     * @type {Boolean}
     *
     * @since 1.0.0
     */
    hasRouteProperty: Ember.computed("route", function () {
      return this.route ? this.route.toString() !== "UNDEFINED" : false;
    }),

    /**
     * @deprecated Don't use positional params.
     *
     * @protected
     * @since 1.0.0
     */
    didReceiveAttrs() {
      if (this._didReceiveAttrs) {
        this._didReceiveAttrs = false;
        return;
      }

      if (!this.hasRouteProperty) {
        this._didReceiveAttrs = true;
        let icon = this.get("icon");
        const params = Ember.A(this.get('params'));

        if (!icon && params.length > 1) {
          icon = params.shiftObject();
          this.set("icon", icon);
        }

        params.unshiftObject("");
        this.set("params", params);
      }

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});