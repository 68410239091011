define("@fantasy-account/webapp/templates/components/payment-method/neteller/deposit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5QLy3KLv",
    "block": "{\"symbols\":[\"Field\",\"&attrs\"],\"statements\":[[8,\"form\",[[16,0,[32,0,[\"className\"]]],[17,2]],[[\"@delegate\"],[[32,0]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Text\"]],[[4,[38,0],null,[[\"delay\"],[0.5]]]],[[\"@theme\",\"@name\",\"@label\",\"@max\",\"@type\"],[\"fantasy-account\",\"email\",\"Your Neteller E-mail\",50,\"email\"]],null],[2,\"\\n\\n  \"],[8,[32,1,[\"Submit\"]],[[16,0,[31,[[32,0,[\"className\"]],\"__submit\"]]]],[[\"@theme\",\"@label\",\"@modifier\"],[\"fantasy-account-accent\",\"Make Deposit\",\"flat block\"]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"autofocus\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/payment-method/neteller/deposit.hbs"
    }
  });

  _exports.default = _default;
});