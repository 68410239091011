define("@fantasy-account/webapp/templates/components/filters/popover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3T/sc3iO",
    "block": "{\"symbols\":[\"@name\",\"@placement\",\"@filters\",\"@applyButtonLabel\"],\"statements\":[[8,\"popover\",[[24,0,\"filters--popover\"]],[[\"@name\",\"@placement\"],[[32,1],[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"filters\",[],[[\"@filters\",\"@applyButtonLabel\",\"@onApply\"],[[32,3],[32,4],[32,0,[\"apply\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/filters/popover.hbs"
    }
  });

  _exports.default = _default;
});