define("@fantasy-account/webapp/services/fetch", ["exports", "@playon/framework/services/fetch", "@playon/framework/data", "@playon/framework/mixins/fetchable"], function (_exports, _fetch, _data, _fetchable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @public
   *
   * @class FetchService
   * @extends BaseFetchService
   * @mixes AuthenticableAdapter
   * @mixes HasHeadersAdapter
   * @mixes Fetchable
   *
   * @since 1.0.0
   */
  var _default = _fetch.default.extend(_fetchable.default, _data.HasHeadersAdapter, _data.AuthenticableAdapter, {});

  _exports.default = _default;
});