define("@fantasy-account/webapp/decorator/array/index", ["exports", "@playon/framework/decorator/array"], function (_exports, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "filterBy", {
    enumerable: true,
    get: function () {
      return _array.filterBy;
    }
  });
  Object.defineProperty(_exports, "findBy", {
    enumerable: true,
    get: function () {
      return _array.findBy;
    }
  });
  Object.defineProperty(_exports, "isAny", {
    enumerable: true,
    get: function () {
      return _array.isAny;
    }
  });
  Object.defineProperty(_exports, "isEvery", {
    enumerable: true,
    get: function () {
      return _array.isEvery;
    }
  });
  Object.defineProperty(_exports, "max", {
    enumerable: true,
    get: function () {
      return _array.max;
    }
  });
  Object.defineProperty(_exports, "min", {
    enumerable: true,
    get: function () {
      return _array.min;
    }
  });
  Object.defineProperty(_exports, "rejectBy", {
    enumerable: true,
    get: function () {
      return _array.rejectBy;
    }
  });
  Object.defineProperty(_exports, "sum", {
    enumerable: true,
    get: function () {
      return _array.sum;
    }
  });
});