define("@fantasy-account/webapp/templates/components/description-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5UM29ZJj",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,4],[[35,3]],[[\"bordered\",\"alignment\",\"classLayout\"],[[35,2],[35,1],[35,0]]]]],[2,\"\\n\"],[1,[30,[36,9],[[35,8]],[[\"bordered\",\"alignment\",\"classLayout\"],[[35,7],[35,6],[35,5]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"termLayout\",\"termAlignment\",\"termBordered\",\"term\",\"description-list-term\",\"descriptionLayout\",\"descriptionAlignment\",\"descriptionBordered\",\"description\",\"description-list-description\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/description-list-item.hbs"
    }
  });

  _exports.default = _default;
});