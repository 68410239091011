define("@fantasy-account/webapp/templates/reset-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TnVhAEVC",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"fantasyAccount\",\"isApplication\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"link-to\",[[24,0,\"auth__top-bar\"]],[[\"@route\"],[\"web.index\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"logo\",[],[[\"@kind\",\"@alt\",\"@modifier\"],[\"fantasy-account\",\"Fantasy Account Logo\",\"centered\"]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"reset-password\"],[12],[2,\"\\n  \"],[8,\"reset-password/form\",[[24,0,\"reset-password__form\"]],[[],[]],null],[2,\"\\n\\n  \"],[10,\"footer\"],[14,0,\"reset-password__footer text--sm text--muted\"],[12],[2,\"\\n    \"],[1,[30,[36,2],[\"auth.copyright\"],[[\"year\"],[[30,[36,1],null,null]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"current-year\",\"t\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/reset-password.hbs"
    }
  });

  _exports.default = _default;
});