define("@fantasy-account/webapp/templates/components/list-header-column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CG95OILI",
    "block": "{\"symbols\":[],\"statements\":[[1,[32,0,[\"label\"]]],[2,\"\\n\\n\"],[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[35,0]],[[\"class\"],[\"list-header__column__icon\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"icon\",\"icon-view\",\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/list-header-column.hbs"
    }
  });

  _exports.default = _default;
});