define("@fantasy-account/webapp/templates/components/list-jumbo-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f+pdr0EK",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"inner\",\"mobile\",\"desktop\",\"link\",\"icon-left\",\"label\",\"part\"],[[30,[36,0],[[35,0]],[[\"row\"],[[30,[36,1],null,[[\"link\",\"icon-left\",\"label\",\"part\"],[[30,[36,0],[\"link-to\"],[[\"class\"],[\"list__row__link\"]]],[30,[36,0],[\"label-view\"],[[\"class\"],[\"list__row__icon list__row__icon--left\"]]],[30,[36,0],[\"list-row-label\"],null],[30,[36,0],[\"list-row-part\"],null]]]]]]],[30,[36,0],[[35,0]],[[\"breakpoint\",\"row\"],[\"mobile\",[30,[36,1],null,[[\"link\",\"icon-left\",\"label\",\"part\"],[[30,[36,0],[\"link-to\"],[[\"class\"],[\"list__row__link\"]]],[30,[36,0],[\"label-view\"],[[\"class\"],[\"list__row__icon list__row__icon--left\"]]],[30,[36,0],[\"list-row-label\"],null],[30,[36,0],[\"list-row-part\"],null]]]]]]],[30,[36,0],[[35,0]],[[\"breakpoint\",\"row\"],[\"desktop\",[30,[36,1],null,[[\"link\",\"icon-left\",\"label\",\"part\"],[[30,[36,0],[\"link-to\"],[[\"class\"],[\"list__row__link\"]]],[30,[36,0],[\"label-view\"],[[\"class\"],[\"list__row__icon list__row__icon--left\"]]],[30,[36,0],[\"list-row-label\"],null],[30,[36,0],[\"list-row-part\"],null]]]]]]],[30,[36,0],[\"link-to\"],[[\"class\"],[\"list__row__link\"]]],[30,[36,0],[\"label-view\"],[[\"class\"],[\"list__row__icon list__row__icon--left\"]]],[30,[36,0],[\"list-row-label\"],null],[30,[36,0],[\"list-row-part\"],null]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/list-jumbo-row.hbs"
    }
  });

  _exports.default = _default;
});