define("@fantasy-account/webapp/components/verification/status-stepper/email", ["exports", "@fantasy-account/engine/components/verification/status-stepper/email"], function (_exports, _email) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _email.default;
    }
  });
});