define("@fantasy-account/webapp/sorting/index", ["exports", "@playon/framework/sorting"], function (_exports, _sorting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "Sorter", {
    enumerable: true,
    get: function () {
      return _sorting.Sorter;
    }
  });
  Object.defineProperty(_exports, "Sorters", {
    enumerable: true,
    get: function () {
      return _sorting.Sorters;
    }
  });
  Object.defineProperty(_exports, "sortByMomentDateAsc", {
    enumerable: true,
    get: function () {
      return _sorting.sortByMomentDateAsc;
    }
  });
  Object.defineProperty(_exports, "sortByMomentDateDesc", {
    enumerable: true,
    get: function () {
      return _sorting.sortByMomentDateDesc;
    }
  });
});