define("@fantasy-account/webapp/templates/components/navigation-drawer/menu/action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9D1uOpRF",
    "block": "{\"symbols\":[\"&attrs\",\"@icon\",\"@label\"],\"statements\":[[11,\"button\"],[24,0,\"navigation-drawer__menu__item__action navigation-drawer__menu__item__action--button\"],[17,1],[4,[38,0],[[32,0]],null],[4,[38,1],[\"click\",[32,0,[\"click\"]]],null],[12],[2,\"\\n  \"],[8,\"icon\",[[24,0,\"navigation-drawer__menu__item__icon\"]],[[\"@icon\"],[[32,2]]],null],[2,\"\\n  \"],[10,\"span\"],[14,0,\"navigation-drawer__menu__item__label\"],[12],[1,[32,3]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"on\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/navigation-drawer/menu/action.hbs"
    }
  });

  _exports.default = _default;
});