define("@fantasy-account/webapp/adapters/ott/payment", ["exports", "@fantasy-account/webapp/adapters/ott/application", "@playon/framework/ott/adapters/payment"], function (_exports, _application, _payment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @public
   *
   * @class OttF1SessionAdapter
   * @extends OttAdapter
   * @mixes OttPaymentAdapterMixin
   *
   * @since 1.0.0
   */
  var _default = _application.default.extend(_payment.default, {});

  _exports.default = _default;
});