define("@fantasy-account/webapp/templates/components/navigation-drawer/menu/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "faN59346",
    "block": "{\"symbols\":[\"@rightIcon\",\"@route\",\"@target\",\"&attrs\",\"@icon\",\"@label\"],\"statements\":[[8,\"link-to\",[[16,0,[31,[\"navigation-drawer__menu__item__action navigation-drawer__menu__item__action--route navigation-drawer__menu__item__action--\",[32,2]]]],[17,4],[4,[38,0],[[32,0]],null],[4,[38,1],[\"click\",[32,0,[\"click\"]]],null]],[[\"@route\",\"@activeClass\",\"@disabledClass\",\"@target\",\"@push\",\"@pop\",\"@present\",\"@dismiss\"],[[32,2],\"navigation-drawer__menu__item__action--active\",\"navigation-drawer__menu__item__action--disabled\",[32,3],[32,0,[\"push\"]],[32,0,[\"pop\"]],[32,0,[\"present\"]],[32,0,[\"dismiss\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"icon\",[[24,0,\"navigation-drawer__menu__item__icon navigation-drawer__menu__item__icon--left\"]],[[\"@icon\"],[[32,5]]],null],[2,\"\\n  \"],[10,\"span\"],[14,0,\"navigation-drawer__menu__item__label\"],[12],[1,[32,6]],[13],[2,\"\\n\\n\"],[6,[37,2],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"icon\",[[24,0,\"navigation-drawer__menu__item__icon navigation-drawer__menu__item__icon--right\"]],[[\"@icon\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"on\",\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/navigation-drawer/menu/route.hbs"
    }
  });

  _exports.default = _default;
});