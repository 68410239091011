define("@fantasy-account/webapp/components/data-table-cell", ["exports", "@playon/framework/components/data-table-cell"], function (_exports, _dataTableCell) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _dataTableCell.default;
    }
  });
});