define("@fantasy-account/webapp/templates/components/modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "12VQwjtn",
    "block": "{\"symbols\":[\"&default\",\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[31,[[32,0,[\"name\"]],\" \",[32,0,[\"classes\"]]]]],[17,2],[4,[38,2],[[32,0,[\"didInsert\"]]],null],[4,[38,3],[\"click\",[32,0,[\"click\"]]],null],[12],[2,\"\\n\"],[6,[37,4],[[32,0,[\"shouldBeRendered\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[\"modal__frame \",[32,0,[\"name\"]],\"__frame\"]]],[12],[2,\"\\n      \"],[10,\"div\"],[15,0,[31,[\"modal__content \",[32,0,[\"name\"]],\"__content\"]]],[12],[2,\"\\n        \"],[18,1,[[30,[36,1],null,[[\"Header\",\"Footer\",\"Body\"],[[30,[36,0],[\"modal/header\"],[[\"modal\"],[[32,0,[\"name\"]]]]],[30,[36,0],[\"modal/footer\"],[[\"modal\"],[[32,0,[\"name\"]]]]],[30,[36,0],[\"modal/body\"],[[\"modal\"],[[32,0,[\"name\"]]]]]]]]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"did-insert\",\"on\",\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/modal.hbs"
    }
  });

  _exports.default = _default;
});