define("@fantasy-account/webapp/templates/components/payment-method/form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cWDqxU/x",
    "block": "{\"symbols\":[\"&attrs\",\"@paymentMethod\"],\"statements\":[[8,\"animated-container\",[[16,0,[32,0,[\"className\"]]],[17,1],[4,[38,1],[[32,0],\"paymentMethod\",[32,2]],null]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[32,0,[\"paymentMethodNeedsForm\"]]],[[\"use\",\"duration\"],[[32,0,[\"fade\"]],350]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],[[32,0,[\"innerComponent\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"did-receive-arg\",\"animated-if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/payment-method/form.hbs"
    }
  });

  _exports.default = _default;
});