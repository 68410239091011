define("@fantasy-account/webapp/templates/components/carousel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VyUZC4ot",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"carousel\"],[17,1],[4,[38,0],[[32,0,[\"didInsert\"]]],null],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"carousel__track\"],[14,\"data-glide-el\",\"track\"],[12],[2,\"\\n    \"],[11,\"ul\"],[24,0,\"carousel__slides\"],[4,[38,0],[[32,0,[\"didInsertSlides\"]]],null],[12],[2,\"\\n      \"],[18,2,[[30,[36,3],null,[[\"Slide\",\"Card\"],[[30,[36,2],[\"carousel/slide\"],[[\"onInsert\",\"onClick\"],[[32,0,[\"didInsertSlide\"]],[32,0,[\"slideWasClicked\"]]]]],[30,[36,2],[\"carousel/slide\"],[[\"nestedModifiers\",\"onClick\"],[\"card\",[30,[36,1],[[32,0],\"slideWasClicked\"],null]]]]]]]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[18,2,[[30,[36,3],null,[[\"LeftButton\",\"RightButton\",\"Bullets\"],[[30,[36,2],[\"icon-button\"],[[\"icon\",\"class\",\"action\"],[\"chevron-left:fa:fas\",\"carousel__button carousel__button--left\",[30,[36,1],[[32,0],\"previousSlide\"],null]]]],[30,[36,2],[\"icon-button\"],[[\"icon\",\"class\",\"action\"],[\"chevron-right:fa:fas\",\"carousel__button carousel__button--right\",[30,[36,1],[[32,0],\"nextSlide\"],null]]]],[30,[36,2],[\"carousel/bullets\"],[[\"bulletsCount\"],[[32,0,[\"slidesCount\"]]]]]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"action\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/carousel.hbs"
    }
  });

  _exports.default = _default;
});