define("@fantasy-account/webapp/templates/components/dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+8XTS6Ef",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"dropdown__row\"],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"label\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"dropdown__label\"],[12],[1,[32,0,[\"label\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[1,[30,[36,8],[[35,7]],[[\"icon\",\"kind\",\"disabled\",\"theme\",\"modifier\",\"nestedModifiers\",\"class\",\"action\"],[[35,6],[35,5],[35,4],[35,3],[35,2],\"icon-right-aligned\",\"dropdown__button\",[30,[36,1],[[32,0],\"toggleMenu\"],null]]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"ul\"],[14,0,\"menu__items dropdown__menu__items\"],[15,5,[34,9]],[12],[2,\"\\n  \"],[18,1,[[30,[36,11],null,[[\"item\",\"Item\"],[[30,[36,10],[\"menu/item\"],[[\"parent\",\"action\"],[[32,0],[30,[36,1],[[32,0],\"menuItemSelected\"],null]]]],[30,[36,10],[\"menu/item\"],[[\"parent\",\"action\"],[[32,0],[30,[36,1],[[32,0],\"menuItemSelected\"],null]]]]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"modifier\",\"theme\",\"disabled\",\"type\",\"icon\",\"buttonLabel\",\"button-view\",\"menuStyle\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/dropdown.hbs"
    }
  });

  _exports.default = _default;
});