define("@fantasy-account/webapp/array/index", ["exports", "@playon/framework/array"], function (_exports, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "combine", {
    enumerable: true,
    get: function () {
      return _array.combine;
    }
  });
  Object.defineProperty(_exports, "flatten", {
    enumerable: true,
    get: function () {
      return _array.flatten;
    }
  });
  Object.defineProperty(_exports, "zip", {
    enumerable: true,
    get: function () {
      return _array.zip;
    }
  });
});