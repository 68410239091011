define("@fantasy-account/webapp/templates/components/popover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rFY4QeQh",
    "block": "{\"symbols\":[\"&default\",\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"popover-view \",[32,0,[\"classes\"]]]]],[17,2],[4,[38,4],[[32,0,[\"didInsert\"]]],null],[4,[38,5],[\"click\",[32,0,[\"click\"]]],null],[12],[2,\"\\n\"],[6,[37,6],[[32,0,[\"backdrop\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"popover__arrow\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"popover__bubble\"],[12],[2,\"\\n\"],[6,[37,3],[[32,0,[\"backdrop\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"popover__arrow\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"div\"],[14,0,\"popover__content\"],[12],[2,\"\\n\"],[6,[37,3],[[32,0,[\"content\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,3],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[18,1,[[30,[36,0],null,[[\"inner\"],[[30,[36,2],[[32,0,[\"content\"]]],[[\"popover\"],[[32,0,[\"name\"]]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[1,[30,[36,2],[[32,0,[\"content\"]]],[[\"popover\"],[[35,1]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[18,1,[[30,[36,0],null,[[\"popover\"],[[32,0,[\"name\"]]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\",\"name\",\"component\",\"if\",\"did-insert\",\"on\",\"unless\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/popover.hbs"
    }
  });

  _exports.default = _default;
});