define("@fantasy-account/webapp/templates/components/verification/panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AaG1JxzL",
    "block": "{\"symbols\":[\"icon\",\"@icon\",\"&attrs\",\"&default\",\"@title\"],\"statements\":[[11,\"div\"],[16,0,[32,0,[\"className\"]]],[17,3],[12],[2,\"\\n  \"],[18,4,[[30,[36,2],null,[[\"Header\"],[[30,[36,1],[\"nested-content\"],null]]]]]],[2,\"\\n\\n\"],[6,[37,3],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"animated-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,2]],[[\"use\"],[[32,0,[\"transition\"]]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"icon\",[[16,0,[31,[[32,0,[\"className\"]],\"__icon\"]]]],[[\"@icon\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"h2\"],[15,0,[31,[[32,0,[\"className\"]],\"__title\"]]],[12],[1,[32,5]],[13],[2,\"\\n\\n  \"],[10,\"div\"],[15,0,[31,[[32,0,[\"className\"]],\"__body\"]]],[12],[2,\"\\n    \"],[18,4,[[30,[36,2],null,[[\"Body\"],[[30,[36,1],[\"nested-content\"],null]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"animated-value\",\"component\",\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/verification/panel.hbs"
    }
  });

  _exports.default = _default;
});