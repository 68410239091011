define("@fantasy-account/webapp/formatters/index", ["exports", "@playon/framework/formatters"], function (_exports, _formatters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "DefaultFormatter", {
    enumerable: true,
    get: function () {
      return _formatters.DefaultFormatter;
    }
  });
  Object.defineProperty(_exports, "Formatter", {
    enumerable: true,
    get: function () {
      return _formatters.Formatter;
    }
  });
  Object.defineProperty(_exports, "I18nFormatter", {
    enumerable: true,
    get: function () {
      return _formatters.I18nFormatter;
    }
  });
  Object.defineProperty(_exports, "ShortNumberFormatter", {
    enumerable: true,
    get: function () {
      return _formatters.ShortNumberFormatter;
    }
  });
});