define("@fantasy-account/webapp/templates/components/fantasy-account/wizard/step", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WxsTOITz",
    "block": "{\"symbols\":[\"&default\",\"@id\",\"@current\"],\"statements\":[[6,[37,1],[[30,[36,0],[[32,3],[32,2]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"equal\",\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/fantasy-account/wizard/step.hbs"
    }
  });

  _exports.default = _default;
});