define("@fantasy-account/webapp/templates/components/scroll-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H05ePRi4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[32,0]]],[2,\"\\n\"],[10,\"span\"],[14,0,\"scroll-view__bottom\"],[12],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/scroll-view.hbs"
    }
  });

  _exports.default = _default;
});