define("@fantasy-account/webapp/templates/components/filters/range-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9ChTQxAj",
    "block": "{\"symbols\":[],\"statements\":[[8,\"filters/label\",[],[[\"@label\",\"@labelKey\",\"@hasLabel\"],[[32,0,[\"filter\",\"label\"]],[32,0,[\"filter\",\"labelKey\"]],[32,0,[\"filter\",\"hasLabel\"]]]],null],[2,\"\\n\\n\"],[8,\"slider\",[],[[\"@value\",\"@rightValue\",\"@min\",\"@max\",\"@labels\",\"@modifier\",\"@step\",\"@onChange\"],[[32,0,[\"leftValue\"]],[32,0,[\"rightValue\"]],[32,0,[\"min\"]],[32,0,[\"max\"]],true,[32,0,[\"filter\",\"controlModifiers\"]],[32,0,[\"filter\",\"step\"]],[30,[36,0],[[32,0],\"onChange\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/filters/range-filter.hbs"
    }
  });

  _exports.default = _default;
});