define("@fantasy-account/webapp/templates/components/modal/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HZ/DX/8d",
    "block": "{\"symbols\":[\"&default\",\"&attrs\"],\"statements\":[[11,\"header\"],[16,0,[31,[[32,0,[\"className\"]],\" \",[32,0,[\"modal\"]],\"__header\"]]],[17,2],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"title\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[\"modal__header__title \",[32,0,[\"modal\"]],\"__header__title\"]]],[12],[2,\"\\n      \"],[1,[32,0,[\"title\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/modal/header.hbs"
    }
  });

  _exports.default = _default;
});