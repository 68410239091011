define("@fantasy-account/webapp/routes/opt-in", ["exports", "@fantasy-account/engine/routes/opt-in"], function (_exports, _optIn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _optIn.default;
    }
  });
});