define("@fantasy-account/webapp/templates/components/account/status-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XZ0aZRH5",
    "block": "{\"symbols\":[\"@message\",\"@title\"],\"statements\":[[10,\"div\"],[14,0,\"account-status-panel\"],[12],[2,\"\\n  \"],[10,\"h2\"],[14,0,\"account-status-panel__title\"],[12],[2,\"\\n    \"],[1,[32,2]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[14,0,\"account-status-panel__message\"],[12],[2,\"\\n      \"],[1,[32,1]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"footer\"],[14,0,\"account-status-panel__footer\"],[12],[2,\"\\n    \"],[2,[30,[36,1],[\"account.status.self-exclude\"],[[\"link\"],[\"<a class=\\\"fantasy-account-theme\\\" href=\\\"https://www.gamstop.co.uk\\\" target=\\\"gamstop\\\">www.gamstop.co.uk</a>\"]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"t\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/account/status-panel.hbs"
    }
  });

  _exports.default = _default;
});