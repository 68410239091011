define("@fantasy-account/webapp/templates/components/segmented-control", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K3eAiDhY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,2],null,[[\"Item\",\"IconItem\"],[[30,[36,1],[\"text-button\"],[[\"selection\",\"action\",\"nestedModifiers\",\"nestedClasses\"],[[32,0,[\"selected\"]],[30,[36,0],[[32,0],\"itemClicked\"],null],\"rect\",\"segmented-control__item segmented-control__item--default\"]]],[30,[36,1],[\"icon-button\"],[[\"selection\",\"nestedModifiers\",\"action\",\"nestedClasses\"],[[32,0,[\"selected\"]],\"dark\",[30,[36,0],[[32,0],\"itemClicked\"],null],\"segmented-control__item segmented-control__item--icon\"]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/segmented-control.hbs"
    }
  });

  _exports.default = _default;
});