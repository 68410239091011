define("@fantasy-account/webapp/components/list-row-label", ["exports", "@playon/framework/components/list-row-label"], function (_exports, _listRowLabel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _listRowLabel.default;
    }
  });
});