define("@fantasy-account/webapp/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WXKy7H8t",
    "block": "{\"symbols\":[],\"statements\":[[3,\" ember-cli-head/templates/head.hbs \"],[2,\"\\n\"],[3,\" If you see this your application's `head.hbs` has gone missing. \"],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/head.hbs"
    }
  });

  _exports.default = _default;
});