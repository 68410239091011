define("@fantasy-account/webapp/templates/components/slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T9MUJDzb",
    "block": "{\"symbols\":[\"&attrs\",\"@value\",\"@rightValue\"],\"statements\":[[11,\"div\"],[16,0,[32,0,[\"classes\"]]],[16,\"disabled\",[32,0,[\"disabled\"]]],[17,1],[4,[38,0],[[32,0]],null],[4,[38,1],[[32,0],\"value\",[32,2]],null],[4,[38,1],[[32,0],\"rightValue\",[32,3]],null],[12],[2,\"\\n\"],[6,[37,2],[[32,0,[\"label\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"slider__label\"],[12],[2,\"\\n      \"],[1,[32,0,[\"label\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[15,\"disabled\",[32,0,[\"disabled\"]]],[14,0,\"slider__wrapper\"],[12],[13],[2,\"\\n\\n\"],[6,[37,2],[[32,0,[\"labels\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"slider__labels\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"slider__labels__left\"],[12],[2,\"\\n        \"],[1,[32,0,[\"leftLabel\"]]],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"slider__labels__right\"],[12],[2,\"\\n        \"],[1,[32,0,[\"rightLabel\"]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"did-update-arg\",\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/slider.hbs"
    }
  });

  _exports.default = _default;
});