define("@fantasy-account/webapp/templates/components/withdraw/wizard/payment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9ORD8BBk",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[17,1],[12],[2,\"\\n  \"],[8,\"payment-methods/default-picker\",[],[[\"@selected\",\"@onChange\"],[[32,0,[\"paymentMethodType\"]],[32,0,[\"changePaymentMethod\"]]]],null],[2,\"\\n  \"],[8,\"payment-method/form\",[],[[\"@type\",\"@paymentMethod\"],[\"withdraw\",[32,0,[\"paymentMethodType\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/withdraw/wizard/payment.hbs"
    }
  });

  _exports.default = _default;
});