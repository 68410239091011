define("@fantasy-account/webapp/templates/components/menu-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ozy15DwL",
    "block": "{\"symbols\":[\"&attrs\",\"@selected\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[32,0,[\"classes\"]]],[17,1],[4,[38,0],[[32,0,[\"didInsert\"]]],null],[4,[38,1],[[32,0],\"selected\",[32,2]],null],[4,[38,2],[\"click\",[32,0,[\"click\"]]],null],[12],[2,\"\\n  \"],[10,\"ul\"],[14,0,\"menu__items\"],[12],[2,\"\\n    \"],[18,3,[[30,[36,4],null,[[\"Item\",\"Option\",\"Separator\",\"Header\",\"Custom\"],[[30,[36,3],[[32,0,[\"itemComponent\"]]],[[\"parent\"],[[32,0]]]],[30,[36,3],[[32,0,[\"itemComponent\"]]],[[\"parent\",\"selectable\",\"selection\",\"action\"],[[32,0],true,[32,0,[\"selected\"]],[32,0,[\"selectItem\"]]]]],[30,[36,3],[\"menu/separator\"],null],[30,[36,3],[\"menu/header\"],null],[30,[36,3],[\"menu/custom\"],null]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"did-update-arg\",\"on\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/menu-view.hbs"
    }
  });

  _exports.default = _default;
});