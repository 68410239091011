define("@fantasy-account/webapp/templates/components/account/profile/edit/username", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mVuSWoxR",
    "block": "{\"symbols\":[\"Field\",\"@onClose\"],\"statements\":[[8,\"form\",[[24,0,\"form__username\"]],[[\"@delegate\",\"@modifier\"],[[32,0],\"block\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Text\"]],[[4,[38,0],null,[[\"delay\"],[0.5]]]],[[\"@theme\",\"@name\",\"@label\",\"@min\",\"@max\",\"@validateFor\"],[\"fantasy-account\",\"username\",\"Username\",2,50,[32,0,[\"elementId\"]]]],null],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"username__form__actions\"],[12],[2,\"\\n    \"],[8,\"contained-button\",[[24,0,\"username__form__actions__action\"]],[[\"@theme\",\"@label\",\"@modifier\",\"@action\"],[\"white\",\"Cancel\",\"sm flat\",[32,2]]],null],[2,\"\\n    \"],[8,[32,1,[\"Submit\"]],[],[[\"@theme\",\"@label\",\"@modifier\"],[\"fantasy-account\",\"Done\",\"flat sm\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"autofocus\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/account/profile/edit/username.hbs"
    }
  });

  _exports.default = _default;
});