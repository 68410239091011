define("@fantasy-account/webapp/components/tab-bar-link", ["exports", "@playon/framework/components/tab-bar-link"], function (_exports, _tabBarLink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _tabBarLink.default;
    }
  });
});