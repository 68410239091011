define("@fantasy-account/webapp/templates/components/deposit/wizard/amount", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P74Fdu83",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[32,0,[\"className\"]]],[17,1],[12],[2,\"\\n  \"],[8,\"fantasy-account/amount/default-picker\",[],[[\"@customAmountLabel\",\"@value\",\"@onChange\"],[\"Enter Amount to Deposit\",[32,0,[\"wizard\",\"amount\"]],[32,0,[\"wizard\",\"selectAmount\"]]]],null],[2,\"\\n\\n  \"],[8,\"contained-button\",[[16,0,[31,[[32,0,[\"className\"]],\"__continue-button\"]]]],[[\"@enabled\",\"@modifier\",\"@action\"],[[32,0,[\"step\",\"filled\"]],\"flat block\",[32,0,[\"next\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"continue\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/deposit/wizard/amount.hbs"
    }
  });

  _exports.default = _default;
});