define("@fantasy-account/webapp/components/form/delegate", ["exports", "@playon/framework/components/form/delegate"], function (_exports, _delegate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _delegate.default;
    }
  });
});