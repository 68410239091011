define("@fantasy-account/webapp/templates/components/icon-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zyu1x4ui",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,0],[[35,1]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,3],[[35,1]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[34,1]],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,0],[[32,0,[\"label\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"icon__label\"],[12],[1,[32,0,[\"label\"]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[27,[32,1]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"icon__label\"],[12],[18,1,null],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"icon\",\"isContentIconType\",\"inline-svg\",\"iconIsSvg\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/icon-view.hbs"
    }
  });

  _exports.default = _default;
});