define("@fantasy-account/webapp/templates/components/filters/modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X69d3Tbq",
    "block": "{\"symbols\":[\"ModalView\",\"@name\",\"@theme\",\"@modifier\",\"@heading\",\"@filters\",\"@applyButtonLabel\"],\"statements\":[[8,\"modal\",[[24,0,\"filters--modal\"]],[[\"@name\",\"@theme\",\"@modifier\"],[[32,2],[32,3],[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"icon-button\",[[24,0,\"modal__header__close-button\"]],[[\"@icon\",\"@modal\"],[\"times:fa:fal\",[32,2]]],null],[2,\"\\n    \"],[8,\"bold-heading\",[[24,0,\"modal__header__heading\"]],[[\"@tag\"],[\"h2\"]],[[\"default\"],[{\"statements\":[[1,[32,5]]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"filters\",[],[[\"@filters\",\"@applyButtonLabel\",\"@onApply\"],[[32,6],[32,7],[32,0,[\"apply\"]]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/filters/modal.hbs"
    }
  });

  _exports.default = _default;
});