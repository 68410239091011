define("@fantasy-account/webapp/helpers/progress", ["exports", "@playon/framework/helpers/progress"], function (_exports, _progress) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _progress.default;
    }
  });
});