define("@fantasy-account/webapp/templates/components/payment-method/card/picker-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q7Hk7piW",
    "block": "{\"symbols\":[\"@className\"],\"statements\":[[10,\"div\"],[15,0,[31,[[32,1],\"__details\"]]],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[[32,1],\"__label\"]]],[12],[1,[30,[36,0],[[32,0,[\"label\"]]],null]],[13],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[[32,1],\"__detail\"]]],[12],[1,[30,[36,0],[[32,0,[\"detail\"]]],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"ul\"],[15,0,[31,[[32,1],\"__networks\"]]],[12],[2,\"\\n  \"],[8,\"logo\",[[16,0,[31,[[32,1],\"__networks__item\"]]]],[[\"@kind\",\"@name\"],[\"visa\",\"card\"]],null],[2,\"\\n  \"],[8,\"logo\",[[16,0,[31,[[32,1],\"__networks__item\"]]]],[[\"@kind\",\"@name\"],[\"electron\",\"card\"]],null],[2,\"\\n  \"],[8,\"logo\",[[16,0,[31,[[32,1],\"__networks__item\"]]]],[[\"@kind\",\"@name\"],[\"mastercard\",\"card\"]],null],[2,\"\\n  \"],[8,\"logo\",[[16,0,[31,[[32,1],\"__networks__item\"]]]],[[\"@kind\",\"@name\"],[\"maestro\",\"card\"]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/payment-method/card/picker-item.hbs"
    }
  });

  _exports.default = _default;
});