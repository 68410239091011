define("@fantasy-account/webapp/services/device", ["exports", "@playon/framework/services/device"], function (_exports, _device) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "LANDSCAPE_ORIENTATION", {
    enumerable: true,
    get: function () {
      return _device.LANDSCAPE_ORIENTATION;
    }
  });
  Object.defineProperty(_exports, "ORIENTATION_DID_CHANGE_EVENT", {
    enumerable: true,
    get: function () {
      return _device.ORIENTATION_DID_CHANGE_EVENT;
    }
  });
  Object.defineProperty(_exports, "PORTRAIT_ORIENTATION", {
    enumerable: true,
    get: function () {
      return _device.PORTRAIT_ORIENTATION;
    }
  });
  Object.defineProperty(_exports, "SIZE_DID_CHANGE_EVENT", {
    enumerable: true,
    get: function () {
      return _device.SIZE_DID_CHANGE_EVENT;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _device.default;
    }
  });
});