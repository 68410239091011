define("@fantasy-account/webapp/templates/components/accordion-panel/body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3hH8dg6d",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[8,\"animated-container\",[[24,0,\"accordion-panel__body__container\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[35,2]],[[\"use\",\"duration\"],[[35,1],[35,0]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"accordion-panel__body__content\"],[12],[2,\"\\n      \"],[18,1,null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"transitionDuration\",\"transition\",\"visible\",\"animated-if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/accordion-panel/body.hbs"
    }
  });

  _exports.default = _default;
});