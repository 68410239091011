define("@fantasy-account/webapp/components/backdrop-navigation/panel", ["exports", "@playon/framework/components/backdrop-navigation/panel"], function (_exports, _panel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _panel.default;
    }
  });
});