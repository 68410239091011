define("@fantasy-account/webapp/components/filters/dropdown", ["exports", "@playon/framework/components/filters/dropdown"], function (_exports, _dropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _dropdown.default;
    }
  });
});