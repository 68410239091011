define("@fantasy-account/webapp/templates/components/backdrop-navigation/button-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hGpkI1g7",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,3],null,[[\"params\",\"modifier\"],[[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,2],null,[[\"params\",\"modifier\",\"nestedModifiers\"],[[35,1],[35,0],\"sm uppercase\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,5],[[32,0,[\"badge\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"badge\",[[24,0,\"backdrop-navigation__item__badge\"]],[[\"@text\",\"@shape\"],[[32,0,[\"badgeContent\"]],[32,0,[\"badgeShape\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"modifier\",\"params\",\"text-link\",\"icon-link\",\"iconIsPreferred\",\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/backdrop-navigation/button-link.hbs"
    }
  });

  _exports.default = _default;
});