define("@fantasy-account/webapp/utils/initializer", ["exports", "@playon/framework/utils/initializer"], function (_exports, _initializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "fullInjection", {
    enumerable: true,
    get: function () {
      return _initializer.fullInjection;
    }
  });
  Object.defineProperty(_exports, "partialInjection", {
    enumerable: true,
    get: function () {
      return _initializer.partialInjection;
    }
  });
});