define("@fantasy-account/webapp/templates/components/account/settings/language/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KZT3bFL5",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"li\"],[16,0,[32,0,[\"classes\"]]],[17,1],[4,[38,0],[\"click\",[32,0,[\"click\"]]],null],[12],[2,\"\\n  \"],[8,\"flag\",[[16,0,[31,[[32,0,[\"className\"]],\"__flag\"]]]],[[\"@country\",\"@modifier\"],[[32,0,[\"language\",\"alpha2\"]],\"sm rounded unbordered\"]],null],[2,\"\\n  \"],[1,[30,[36,1],[[32,0,[\"language\",\"labelKey\"]]],null]],[2,\"\\n  \"],[8,\"radio-indicator\",[[16,0,[31,[[32,0,[\"className\"]],\"__radio-indicator\"]]]],[[],[]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"on\",\"t\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/account/settings/language/item.hbs"
    }
  });

  _exports.default = _default;
});