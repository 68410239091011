define("@fantasy-account/webapp/templates/components/application/ready", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rItibAEG",
    "block": "{\"symbols\":[\"DialogView\",\"SnackbarView\"],\"statements\":[[8,\"app/container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"app/navigation\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"navigation-drawer\",[],[[\"@aside\",\"@component\"],[[32,0,[\"shouldShowNavigationDrawer\"]],\"navigation-drawer/fantasy-account-content\"]],null],[2,\"\\n\\n    \"],[8,\"app/content\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"top-app-bar\",[],[[\"@sticky\",\"@modifier\"],[true,[32,0,[\"shouldShowBorder\"]]]],null],[2,\"\\n\\n      \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"snackbar\",[],[[\"@name\",\"@presentOnInsert\",\"@closeButton\",\"@transient\",\"@duration\"],[\"cookies\",true,true,true,0]],[[\"default\"],[{\"statements\":[[2,\"\\n  By using this website, you consent to the use of cookies in accordance with our Privacy Policy\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"snackbar\",[],[[\"@branded\",\"@position\",\"@theme\"],[true,\"top\",\"fantasy-account\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,2,[\"Logo\"]],[],[[\"@kind\",\"@name\",\"@alt\"],[\"fantasy-account\",\"logotype\",\"Fantasy Account Logotype\"]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\n\\n\"],[8,\"dialog\",[],[[\"@branded\",\"@animation\",\"@theme\"],[true,\"slide-up\",\"fantasy-account\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Logo\"]],[],[[\"@kind\",\"@name\",\"@alt\"],[\"fantasy-account\",\"logotype\",\"Fantasy Account Logotype\"]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/application/ready.hbs"
    }
  });

  _exports.default = _default;
});