define("@fantasy-account/webapp/templates/components/backdrop-navigation/button-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BmL0GPYb",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,8],[[35,7]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,6],[[35,5]],[[\"modifier\",\"action\",\"sheet\"],[[35,1],[30,[36,0],[[32,0],\"onClick\"],null],[35,4]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,3],[[35,2]],[[\"modifier\",\"nestedModifiers\",\"action\"],[[35,1],\"sm uppercase\",[30,[36,0],[[32,0],\"onClick\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,8],[[32,0,[\"badge\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"badge\",[[24,0,\"backdrop-navigation__item__badge\"]],[[\"@text\",\"@shape\"],[[32,0,[\"badgeContent\"]],[32,0,[\"badgeShape\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"modifier\",\"label\",\"text-button\",\"sheet\",\"icon\",\"icon-button\",\"iconIsPreferred\",\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/backdrop-navigation/button-item.hbs"
    }
  });

  _exports.default = _default;
});