define("@fantasy-account/webapp/templates/components/tab-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "37L99VBo",
    "block": "{\"symbols\":[\"&attrs\",\"@value\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[32,0,[\"classes\"]]],[17,1],[4,[38,0],[[32,0],\"value\",[32,2]],null],[12],[2,\"\\n  \"],[10,\"ul\"],[14,0,\"tab-bar__scroller\"],[12],[2,\"\\n    \"],[18,3,[[30,[36,2],null,[[\"Item\",\"Link\"],[[30,[36,1],[\"tab-bar-item\"],[[\"onInitialize\",\"onFinalize\",\"onClick\"],[[32,0,[\"initializeItem\"]],[32,0,[\"finalizeItem\"]],[32,0,[\"activateTabItem\"]]]]],[30,[36,1],[\"tab-bar-link\"],null]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-update-arg\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/tab-bar.hbs"
    }
  });

  _exports.default = _default;
});