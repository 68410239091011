define("@fantasy-account/webapp/templates/components/backdrop-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mpltdg9T",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,9],[[35,11]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"nav\"],[14,0,\"backdrop-navigation__bar\"],[12],[2,\"\\n    \"],[1,[30,[36,3],[[35,7]],[[\"layoutName\",\"context\",\"bar\",\"backdrop-navigation\"],[[35,6],[35,0],[32,0],[30,[36,5],null,[[\"brand\",\"space\",\"space-flexible\",\"button-item\",\"button-link\",\"view\"],[[30,[36,3],[\"image-view\"],[[\"nestedClasses\"],[\"backdrop-navigation__brand\"]]],[30,[36,3],[\"bar/space\"],[[\"mainClassName\"],[\"backdrop-navigation__space\"]]],[30,[36,3],[\"bar/space\"],[[\"flexible\",\"mainClassName\"],[true,\"backdrop-navigation__space\"]]],[30,[36,3],[\"backdrop-navigation/button-item\"],[[\"action\"],[[30,[36,4],[[32,0],\"onClickButtonItem\"],null]]]],[30,[36,3],[\"backdrop-navigation/button-link\"],null],[30,[36,3],[\"backdrop-navigation/view-item\"],null]]]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"backdrop-navigation__panel\"],[12],[2,\"\\n\"],[6,[37,9],[[35,8]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,3],[[35,2]],[[\"layoutName\",\"context\"],[[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n\"],[6,[37,9],[[35,10]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"backdrop-navigation__gap\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"context\",\"panelTemplate\",\"panelComponent\",\"component\",\"action\",\"hash\",\"contentTemplate\",\"contentComponent\",\"hasPanelTemplate\",\"if\",\"withGap\",\"hasContentTemplate\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/backdrop-navigation.hbs"
    }
  });

  _exports.default = _default;
});