define("@fantasy-account/webapp/templates/components/image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "molwHbbt",
    "block": "{\"symbols\":[\"&attrs\",\"@src\"],\"statements\":[[11,\"img\"],[16,0,[32,0,[\"classes\"]]],[16,\"src\",[32,0,[\"image\"]]],[16,\"alt\",[32,0,[\"alt\"]]],[17,1],[4,[38,0],[[32,0]],null],[4,[38,1],[[32,0],\"src\",[32,2]],null],[4,[38,2],[\"error\",[32,0,[\"onError\"]]],null],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"did-update-arg\",\"on\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/image.hbs"
    }
  });

  _exports.default = _default;
});