define("@fantasy-account/webapp/templates/components/aside-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NtUUje7q",
    "block": "{\"symbols\":[\"filter\"],\"statements\":[[10,\"div\"],[14,0,\"filters__inner\"],[12],[2,\"\\n  \"],[10,\"header\"],[14,0,\"filters__header\"],[12],[2,\"\\n    \"],[1,[30,[36,2],[\"Reset filters\"],[[\"modifier\",\"action\"],[\"reset dark xs\",[30,[36,1],[[32,0],\"reset\"],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"ul\"],[14,0,\"filters__list\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"li\"],[14,0,\"filters__list__item\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[[32,1,[\"component\"]],[32,1]],null]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\\n  \"],[10,\"footer\"],[14,0,\"filters__footer\"],[12],[2,\"\\n    \"],[1,[30,[36,2],[\"Reset filters\"],[[\"modifier\",\"action\"],[\"block dark rect\",[30,[36,1],[[32,0],\"reset\"],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"component\",\"action\",\"text-button\",\"filters\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/aside-filters.hbs"
    }
  });

  _exports.default = _default;
});