define("@fantasy-account/webapp/templates/components/modal/aside/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XtduwJMe",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"ul\"],[16,0,[32,0,[\"classes\"]]],[17,1],[12],[2,\"\\n  \"],[18,2,[[30,[36,1],null,[[\"Item\"],[[30,[36,0],[\"modal/aside/list-row\"],null]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/modal/aside/list.hbs"
    }
  });

  _exports.default = _default;
});