define("@fantasy-account/webapp/templates/components/navigation-drawer/menu/link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ul6VwrCM",
    "block": "{\"symbols\":[\"@target\",\"&attrs\",\"@icon\",\"@label\"],\"statements\":[[11,\"a\"],[16,\"target\",[32,1]],[24,0,\"navigation-drawer__menu__item__action navigation-drawer__menu__item__action--link\"],[17,2],[4,[38,0],[[32,0]],null],[4,[38,1],[\"click\",[32,0,[\"click\"]]],null],[12],[2,\"\\n  \"],[8,\"icon\",[[24,0,\"navigation-drawer__menu__item__icon\"]],[[\"@icon\"],[[32,3]]],null],[2,\"\\n  \"],[10,\"span\"],[14,0,\"navigation-drawer__menu__item__label\"],[12],[1,[32,4]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"on\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/navigation-drawer/menu/link.hbs"
    }
  });

  _exports.default = _default;
});