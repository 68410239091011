define("@fantasy-account/webapp/services/tour", ["exports", "@playon/framework/services/tour"], function (_exports, _tour) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _tour.default;
    }
  });
});