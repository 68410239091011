define("@fantasy-account/webapp/components/application/ready", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * @public
   *
   * @class ApplicationReady
   * @extends Component
   *
   * @since 1.18.0
   */
  let ApplicationReady = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.computed("device.type.mobile", "authentication.active", "router.currentRouteName"), _dec6 = Ember.computed("authentication.active"), (_class = class ApplicationReady extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "authentication", _descriptor, this);

      _initializerDefineProperty(this, "fantasyAccount", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "device", _descriptor4, this);
    }

    /**
     * @protected
     * @type {Boolean}
     *
     * @since 1.18.0
     */
    get shouldShowNavigationDrawer() {
      return !(this.device.type.mobile || !this.authentication.active || this.router.currentRouteName.startsWith("web.") || this.router.currentRouteName.startsWith("account.verification") || this.router.currentRouteName.startsWith("account.deposit"));
    }
    /**
     * @protected
     * @type {String}
     *
     * @since 1.18.0
     */


    get shouldShowBorder() {
      if (!this.authentication.active) {
        return "bordered";
      }

      return null;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "authentication", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fantasyAccount", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "device", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "shouldShowNavigationDrawer", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "shouldShowNavigationDrawer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "shouldShowBorder", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "shouldShowBorder"), _class.prototype)), _class));
  var _default = ApplicationReady;
  _exports.default = _default;
});