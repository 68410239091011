define("@fantasy-account/webapp/templates/components/bottom-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R5uPvcV6",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,6],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,[[30,[36,1],null,[[\"item\"],[[30,[36,0],[\"bottom-navigation-item\"],null]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,6],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],[[35,4]],[[\"layoutName\",\"context\",\"bar\",\"bottom-navigation\"],[[35,3],[35,2],[32,0],[30,[36,1],null,[[\"item\"],[[30,[36,0],[\"bottom-navigation-item\"],null]]]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"context\",\"contentTemplate\",\"contentComponent\",\"hasContentTemplate\",\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/bottom-navigation.hbs"
    }
  });

  _exports.default = _default;
});