define("@fantasy-account/webapp/templates/components/user/avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KvusuB8H",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[32,0,[\"className\"]]],[17,1],[12],[2,\"\\n\"],[2,\"  \"],[8,\"icon\",[[16,0,[31,[[32,0,[\"className\"]],\"__icon\"]]]],[[\"@icon\"],[\"user:fa:far\"]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/user/avatar.hbs"
    }
  });

  _exports.default = _default;
});