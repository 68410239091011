define("@fantasy-account/webapp/services/menu-loader", ["exports", "@playon/framework/services/menu-loader"], function (_exports, _menuLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _menuLoader.default;
    }
  });
});