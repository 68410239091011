define("@fantasy-account/webapp/templates/components/fantasy-account/wizard/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+iiUnWHv",
    "block": "{\"symbols\":[\"Step\",\"stepId\",\"step\",\"@wizard\",\"&default\"],\"statements\":[[6,[37,4],[[27,[32,5]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"animated-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[32,4,[\"currentStep\",\"id\"]]],[[\"use\",\"duration\"],[[32,0,[\"transition\"]],[32,0,[\"duration\"]]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[18,5,[[30,[36,0],[\"fantasy-account/wizard/step\"],[[\"current\"],[[32,3]]]]]],[2,\"\\n\"]],\"parameters\":[3]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,4,[\"steps\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"animated-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[32,4,[\"currentStep\",\"id\"]]],[[\"use\",\"duration\"],[[32,0,[\"transition\"]],[32,0,[\"duration\"]]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"fantasy-account/wizard/step\",[],[[\"@current\",\"@id\"],[[32,2],[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[30,[36,0],[[32,1,[\"component\"]]],[[\"wizard\",\"step\"],[[32,4],[32,1]]]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"animated-value\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/fantasy-account/wizard/view.hbs"
    }
  });

  _exports.default = _default;
});