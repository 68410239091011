define("@fantasy-account/webapp/templates/components/tab-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "47hCbiKG",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"tabs__scroller\"],[12],[2,\"\\n  \"],[18,1,[[30,[36,1],null,[[\"item\"],[[30,[36,0],[\"tab-nav-item\"],null]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/tab-nav.hbs"
    }
  });

  _exports.default = _default;
});