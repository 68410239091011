define("@fantasy-account/webapp/scrolling/index", ["exports", "@playon/framework/scrolling"], function (_exports, _scrolling) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "APP_BAR_PROMINENT_TRANSITIONS", {
    enumerable: true,
    get: function () {
      return _scrolling.APP_BAR_PROMINENT_TRANSITIONS;
    }
  });
  Object.defineProperty(_exports, "Pin", {
    enumerable: true,
    get: function () {
      return _scrolling.Pin;
    }
  });
  Object.defineProperty(_exports, "Timeline", {
    enumerable: true,
    get: function () {
      return _scrolling.Timeline;
    }
  });
  Object.defineProperty(_exports, "Transition", {
    enumerable: true,
    get: function () {
      return _scrolling.Transition;
    }
  });
});