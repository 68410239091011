define("@fantasy-account/webapp/trait/component/index", ["exports", "@playon/framework/trait/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "canListenKeyboard", {
    enumerable: true,
    get: function () {
      return _component.canListenKeyboard;
    }
  });
  Object.defineProperty(_exports, "canRefreshScrollView", {
    enumerable: true,
    get: function () {
      return _component.canRefreshScrollView;
    }
  });
  Object.defineProperty(_exports, "canUpdateArguments", {
    enumerable: true,
    get: function () {
      return _component.canUpdateArguments;
    }
  });
  Object.defineProperty(_exports, "classNames", {
    enumerable: true,
    get: function () {
      return _component.classNames;
    }
  });
  Object.defineProperty(_exports, "hasDomElement", {
    enumerable: true,
    get: function () {
      return _component.hasDomElement;
    }
  });
  Object.defineProperty(_exports, "hasMenuItems", {
    enumerable: true,
    get: function () {
      return _component.hasMenuItems;
    }
  });
  Object.defineProperty(_exports, "hasSelectables", {
    enumerable: true,
    get: function () {
      return _component.hasSelectables;
    }
  });
  Object.defineProperty(_exports, "modifierNameBindings", {
    enumerable: true,
    get: function () {
      return _component.modifierNameBindings;
    }
  });
  Object.defineProperty(_exports, "modifierNames", {
    enumerable: true,
    get: function () {
      return _component.modifierNames;
    }
  });
  Object.defineProperty(_exports, "selectable", {
    enumerable: true,
    get: function () {
      return _component.selectable;
    }
  });
  Object.defineProperty(_exports, "transient", {
    enumerable: true,
    get: function () {
      return _component.transient;
    }
  });
  Object.defineProperty(_exports, "validatable", {
    enumerable: true,
    get: function () {
      return _component.validatable;
    }
  });
});