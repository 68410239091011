define("@fantasy-account/webapp/templates/components/bar-button-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xZmApdPn",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[32,0,[\"iconIsPreferred\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],null,[[\"route\",\"models\",\"icon\",\"theme\",\"modifier\"],[[32,0,[\"route\"]],[32,0,[\"models\"]],[32,0,[\"icon\"]],[32,0,[\"theme\"]],[32,0,[\"modifier\"]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,0],[[32,0,[\"itemComponent\"]]],[[\"route\",\"models\",\"label\",\"icon\",\"theme\",\"modifier\"],[[32,0,[\"route\"]],[32,0,[\"models\"]],[32,0,[\"label\"]],[32,0,[\"icon\"]],[32,0,[\"theme\"]],[32,0,[\"modifier\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[32,0,[\"badge\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"badge\",[[24,0,\"app-bar__item__badge\"]],[[\"@text\",\"@shape\"],[[32,0,[\"badgeContent\"]],[32,0,[\"badgeShape\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"icon-link\",\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/bar-button-link.hbs"
    }
  });

  _exports.default = _default;
});