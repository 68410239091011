define("@fantasy-account/webapp/templates/components/menu/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hZ4SIghQ",
    "block": "{\"symbols\":[\"&attrs\",\"@label\"],\"statements\":[[11,\"li\"],[16,0,[32,0,[\"className\"]]],[17,1],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"menu__header__inner\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"menu__header__label\"],[12],[1,[32,2]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/menu/header.hbs"
    }
  });

  _exports.default = _default;
});