define("@fantasy-account/webapp/wizard/index", ["exports", "@playon/framework/wizard"], function (_exports, _wizard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "IndexedWizard", {
    enumerable: true,
    get: function () {
      return _wizard.IndexedWizard;
    }
  });
  Object.defineProperty(_exports, "RoutableWizard", {
    enumerable: true,
    get: function () {
      return _wizard.RoutableWizard;
    }
  });
  Object.defineProperty(_exports, "RouteStep", {
    enumerable: true,
    get: function () {
      return _wizard.RouteStep;
    }
  });
  Object.defineProperty(_exports, "Step", {
    enumerable: true,
    get: function () {
      return _wizard.Step;
    }
  });
  Object.defineProperty(_exports, "inject", {
    enumerable: true,
    get: function () {
      return _wizard.inject;
    }
  });
});