define("@fantasy-account/webapp/validation/rules/digits", ["exports", "@playon/framework/validation/rules/digits"], function (_exports, _digits) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _digits.default;
    }
  });
});