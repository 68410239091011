define("@fantasy-account/webapp/templates/components/alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7/KUagB3",
    "block": "{\"symbols\":[\"&default\",\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[32,0,[\"classes\"]]],[17,2],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"icon\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"icon-view\",[[24,0,\"alert__icon\"]],[[\"@icon\"],[[32,0,[\"icon\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,0,[\"image\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"img\"],[14,0,\"alert__image\"],[14,\"alt\",\"Alert image\"],[14,\"aria-hidden\",\"true\"],[15,\"src\",[32,0,[\"image\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"alert__message\"],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"message\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[32,0,[\"message\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/alert.hbs"
    }
  });

  _exports.default = _default;
});