define("@fantasy-account/webapp/transforms/duration", ["exports", "@playon/framework/data/transforms/duration"], function (_exports, _duration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _duration.default;
    }
  });
});