define("@fantasy-account/webapp/application/initializers/authentication", ["exports", "@playon/framework/authentication/app-initializer"], function (_exports, _appInitializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _appInitializer.default;
    }
  });
});