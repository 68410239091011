define("@fantasy-account/webapp/templates/components/verification/document/take-picture", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8YPJYr7V",
    "block": "{\"symbols\":[\"Hud\"],\"statements\":[[8,\"camera\",[],[[\"@mode\",\"@fullScreen\",\"@onFail\",\"@onTakePhoto\"],[[32,0,[\"cameraMode\"]],true,[32,0,[\"cameraDidFail\"]],[32,0,[\"cameraDidTakePhoto\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,0,[\"isSelfie\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,[32,1,[\"Selfie\"]],[],[[\"@title\",\"@message\"],[[32,0,[\"heading\"]],[32,0,[\"message\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,[32,1,[\"ScanDocument\"]],[],[[\"@title\",\"@message\"],[[32,0,[\"heading\"]],[32,0,[\"message\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/verification/document/take-picture.hbs"
    }
  });

  _exports.default = _default;
});