define("@fantasy-account/webapp/templates/components/carousel/slide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jPx2G8yW",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"li\"],[16,0,[32,0,[\"classes\"]]],[17,1],[4,[38,0],[\"click\",[32,0,[\"click\"]]],null],[4,[38,1],[[32,0,[\"didInsert\"]]],null],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"did-insert\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/carousel/slide.hbs"
    }
  });

  _exports.default = _default;
});