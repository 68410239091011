define("@fantasy-account/webapp/components/contained-anchor", ["exports", "@playon/framework/components/anchor"], function (_exports, _anchor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @public
   *
   * @class ContainedAnchorComponent
   * @extends AnchorComponent
   *
   * @since 1.0.0
   */
  var _default = _anchor.default.extend({
    /**
     * @public
     * @since 1.0.0
     * @type {String}
     */
    type: "contained"
  });

  _exports.default = _default;
});