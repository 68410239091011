define("@fantasy-account/webapp/templates/components/quick-deposit/wizard/summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+X35i0rC",
    "block": "{\"symbols\":[\"Field\",\"&attrs\"],\"statements\":[[8,\"form\",[[16,0,[32,0,[\"className\"]]],[17,2]],[[\"@delegate\"],[[32,0]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"fantasy-account/wizard/form-field\",[],[[\"@label\",\"@onChange\"],[\"Amount\",[32,0,[\"goToSelectAmount\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"fantasy-account/amount\",[[16,0,[31,[[32,0,[\"className\"]],\"__amount\"]]]],[[\"@value\"],[50]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,\"fantasy-account/wizard/form-field\",[],[[\"@label\",\"@onChange\"],[\"Payment Method\",[32,0,[\"goToSelectPaymentMethod\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    Payment Method Here!!!\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Text\"]],[[4,[38,0],null,[[\"delay\"],[0.35]]]],[[\"@name\",\"@label\",\"@type\",\"@theme\"],[\"cvv\",\"CVV\",\"number\",\"fantasy-account\"]],null],[2,\"\\n\\n  \"],[8,[32,1,[\"Submit\"]],[[16,0,[31,[[32,0,[\"className\"]],\"__deposit-button\"]]]],[[\"@theme\",\"@label\",\"@leftIcon\",\"@modifier\"],[\"fantasy-account-accent\",\"Make Deposit\",\"lock-alt:fa:far\",\"flat block\"]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"autofocus\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/quick-deposit/wizard/summary.hbs"
    }
  });

  _exports.default = _default;
});