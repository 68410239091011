define("@fantasy-account/webapp/serializers/ott/f1/subscription", ["exports", "@playon/framework/ott/services/f1/serializers/subscription"], function (_exports, _subscription) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _subscription.default;
    }
  });
});