define("@fantasy-account/webapp/templates/components/filters/dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eF1ZuE11",
    "block": "{\"symbols\":[\"Menu\",\"option\",\"@modifier\",\"&attrs\",\"@theme\"],\"statements\":[[8,\"text-button\",[[24,0,\"filters--dropdown\"],[17,4]],[[\"@menu\",\"@modifier\",\"@icon\",\"@iconPosition\"],[[32,0,[\"name\"]],[32,3],\"chevron-down:fa:fas\",\"right\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[32,0,[\"label\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"menu-view\",[[24,0,\"filters--menu\"]],[[\"@theme\",\"@name\",\"@itemComponent\",\"@selected\",\"@onChange\",\"@placement\"],[[32,5],[32,0,[\"name\"]],[32,0,[\"filters\",\"firstObject\",\"optionComponent\"]],[32,0,[\"filters\",\"firstObject\",\"selected\"]],[32,0,[\"change\"]],\"bottom-end\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"filters\",\"firstObject\",\"options\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,1,[\"Option\"]],[],[[\"@option\",\"@label\"],[[32,2],[30,[36,0],[[32,2,[\"labelKey\"]]],null]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/filters/dropdown.hbs"
    }
  });

  _exports.default = _default;
});