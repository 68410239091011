define("@fantasy-account/webapp/templates/account/verification/document/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xDEV5SNJ",
    "block": "{\"symbols\":[],\"statements\":[[8,\"verification/document/wizard\",[],[[],[]],null],[2,\"\\n\"],[8,\"verification/document/picker\",[],[[\"@type\"],[[32,0,[\"model\",\"type\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/account/verification/document/index.hbs"
    }
  });

  _exports.default = _default;
});