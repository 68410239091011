define("@fantasy-account/webapp/components/underscored-link", ["exports", "@playon/framework/components/link"], function (_exports, _link) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _link.default.extend({
    /**
     * @public
     * @since 1.0.0
     * @type {String}
     */
    type: "underscored"
  });

  _exports.default = _default;
});