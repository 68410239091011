define("@fantasy-account/webapp/templates/components/recaptcha", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vqHl6Cav",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[32,0,[\"className\"]]],[16,\"data-sitekey\",[32,0,[\"dataSiteKey\"]]],[16,\"data-callback\",[32,0,[\"successCallbackName\"]]],[24,\"data-size\",\"invisible\"],[17,1],[4,[38,0],[[32,0]],null],[12],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/recaptcha.hbs"
    }
  });

  _exports.default = _default;
});