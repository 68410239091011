define("@fantasy-account/webapp/authentication/drivers/default", ["exports", "@playon/framework/authentication/drivers/default"], function (_exports, _default) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * @public
   *
   * @class FantasyAccountAuthenticationDriver
   * @extends AuthenticationDriver
   *
   * @since 1.14.0
   */
  class FantasyAccountAuthenticationDriver extends _default.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "identificationAttributeName", 'data.attributes.email');

      _defineProperty(this, "tokenAttributeName", 'data.attributes.jwt');
    }

  }

  _exports.default = FantasyAccountAuthenticationDriver;
});