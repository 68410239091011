define("@fantasy-account/webapp/templates/components/account/settings/language/selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t2otQk3M",
    "block": "{\"symbols\":[\"language\",\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[32,0,[\"className\"]]],[17,2],[12],[2,\"\\n  \"],[10,\"ul\"],[15,0,[31,[[32,0,[\"className\"]],\"__list\"]]],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"application\",\"languages\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"account/settings/language/item\",[],[[\"@language\",\"@selection\",\"@onSelect\"],[[32,1],[32,0,[\"selected\"]],[32,0,[\"onChange\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/account/settings/language/selector.hbs"
    }
  });

  _exports.default = _default;
});