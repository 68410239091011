define("@fantasy-account/webapp/templates/components/countdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CLCVHwLU",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"from\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,1],[[32,0,[\"shouldRenderAsDate\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[32,0,[\"datePrefix\"]]],[2,\" \"],[1,[30,[36,0],[[32,0,[\"from\"]],[32,0,[\"dateFormat\"]]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[32,0,[\"prefix\"]]],[2,\" \"],[1,[32,0,[\"countdown\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[32,0,[\"placeholder\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"format\",\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/countdown.hbs"
    }
  });

  _exports.default = _default;
});