define("@fantasy-account/webapp/routing/index", ["exports", "@playon/framework/routing"], function (_exports, _routing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "Adaptive", {
    enumerable: true,
    get: function () {
      return _routing.Adaptive;
    }
  });
  Object.defineProperty(_exports, "Authenticable", {
    enumerable: true,
    get: function () {
      return _routing.Authenticable;
    }
  });
  Object.defineProperty(_exports, "Authorizable", {
    enumerable: true,
    get: function () {
      return _routing.Authorizable;
    }
  });
  Object.defineProperty(_exports, "HasQueryParams", {
    enumerable: true,
    get: function () {
      return _routing.HasQueryParams;
    }
  });
  Object.defineProperty(_exports, "Traceable", {
    enumerable: true,
    get: function () {
      return _routing.Traceable;
    }
  });
  Object.defineProperty(_exports, "mount", {
    enumerable: true,
    get: function () {
      return _routing.mount;
    }
  });
});