define("@fantasy-account/webapp/templates/components/fantasy-account/amount/default-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C4Q6GbjX",
    "block": "{\"symbols\":[\"AmountItem\",\"amount\",\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[32,0,[\"className\"]]],[17,3],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"heading\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[[32,0,[\"className\"]],\"__heading\"]]],[12],[2,\"\\n      \"],[1,[32,0,[\"heading\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"fantasy-account/amount/picker\",[],[[\"@selected\",\"@onChange\"],[[32,0,[\"wizard\",\"amountSelection\"]],[32,0,[\"selectAmount\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"amounts\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,[32,1],[],[[\"@value\"],[[32,2]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"  \"]],\"parameters\":[1]}]]],[2,\"\\n\\n  \"],[8,\"animated-container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[32,0,[\"isCustom\"]]],[[\"use\",\"duration\"],[[32,0,[\"fade\"]],350]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[15,0,[31,[[32,0,[\"className\"]],\"__text-field\"]]],[12],[2,\"\\n        \"],[8,\"text-field\",[[16,0,[31,[[32,0,[\"className\"]],\"__text-field\"]]],[4,[38,0],null,[[\"delay\"],[0.35]]]],[[\"@value\",\"@label\",\"@type\",\"@min\",\"@step\",\"@theme\"],[[32,0,[\"value\"]],[32,0,[\"customAmountLabel\"]],\"number\",\"1\",\"10\",\"fantasy-account\"]],null],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"autofocus\",\"if\",\"-track-array\",\"each\",\"animated-if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/fantasy-account/amount/default-picker.hbs"
    }
  });

  _exports.default = _default;
});