define("@fantasy-account/webapp/templates/components/text-field/default-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kZc2Mg4B",
    "block": "{\"symbols\":[\"@textField\"],\"statements\":[[10,\"input\"],[14,0,\"text-field__input\"],[15,1,[31,[[32,1,[\"inputId\"]],\"-text-field\"]]],[15,2,[32,1,[\"value\"]]],[15,3,[32,1,[\"name\"]]],[15,\"disabled\",[32,1,[\"disabled\"]]],[15,\"placeholder\",[32,1,[\"placeholder\"]]],[15,\"minlength\",[32,1,[\"min\"]]],[15,\"maxlength\",[32,1,[\"max\"]]],[15,\"min\",[32,1,[\"min\"]]],[15,\"max\",[32,1,[\"max\"]]],[15,\"step\",[32,1,[\"step\"]]],[15,\"autocomplete\",[32,1,[\"autocomplete\"]]],[15,\"readonly\",[32,1,[\"readOnly\"]]],[15,\"oninput\",[30,[36,1],[[32,0],[30,[36,0],[[32,1,[\"value\"]]],null]],[[\"value\"],[\"target.value\"]]]],[15,4,[32,1,[\"type\"]]],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"mut\",\"action\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/text-field/default-input.hbs"
    }
  });

  _exports.default = _default;
});