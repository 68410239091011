define("@fantasy-account/webapp/templates/components/application/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5LG2z+uB",
    "block": "{\"symbols\":[],\"statements\":[[8,\"app/container\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"circular-progress\",[],[[\"@modifier\"],[\"centered\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/application/loading.hbs"
    }
  });

  _exports.default = _default;
});