define("@fantasy-account/webapp/templates/components/user/balance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8/aOz+/5",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[32,0,[\"className\"]]],[17,1],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[[32,0,[\"className\"]],\"__label\"]]],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"user.balance\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[15,0,[31,[[32,0,[\"className\"]],\"__amount\"]]],[12],[2,\"\\n    \"],[1,[30,[36,1],[[32,0,[\"balance\"]],\"currency\"],[[\"currency\"],[\"USD\"]]]],[2,\" \"],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"format\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/user/balance.hbs"
    }
  });

  _exports.default = _default;
});