define("@fantasy-account/webapp/templates/components/account/profile/sheet/username", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BmJucxAQ",
    "block": "{\"symbols\":[\"@onClose\"],\"statements\":[[8,\"sheet\",[],[[\"@name\",\"@delegate\"],[[32,0,[\"sheetName\"]],[32,0]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\"],[8,\"bold-heading\",[[24,0,\"sheet__title\"]],[[\"@tag\",\"@title\"],[\"h4\",\"Change username\"]],null],[2,\"\\n\\t\"],[8,\"icon-button\",[[24,0,\"sheet__close-button\"]],[[\"@theme\",\"@icon\",\"@action\"],[\"grey\",\"times:fa:fal\",[32,1]]],null],[2,\"\\n\\t\"],[8,\"account/profile/edit/username\",[],[[\"@onClose\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/account/profile/sheet/username.hbs"
    }
  });

  _exports.default = _default;
});