define("@fantasy-account/webapp/templates/components/date-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bP6DqZeX",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,0]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"formattedValue\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/date-time.hbs"
    }
  });

  _exports.default = _default;
});