define("@fantasy-account/webapp/templates/account/settings/close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3XHluaCY",
    "block": "{\"symbols\":[],\"statements\":[[8,\"account/close\",[],[[],[]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/account/settings/close.hbs"
    }
  });

  _exports.default = _default;
});