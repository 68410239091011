define("@fantasy-account/webapp/controllers/account/settings/close-account", ["exports", "@fantasy-account/engine/controllers/account/settings/close-account"], function (_exports, _closeAccount) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _closeAccount.default;
    }
  });
});