define("@fantasy-account/webapp/templates/account/verification/home-address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KeK+3z2W",
    "block": "{\"symbols\":[\"Panel\"],\"statements\":[[8,\"verification/panel\",[],[[\"@title\"],[[30,[36,0],[\"verification.home-address.title\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"verification/form/address\",[],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/account/verification/home-address.hbs"
    }
  });

  _exports.default = _default;
});