define("@fantasy-account/webapp/templates/components/wizard/stepper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k4+IbKgm",
    "block": "{\"symbols\":[\"step\",\"&attrs\"],\"statements\":[[11,\"ul\"],[16,0,[32,0,[\"classes\"]]],[17,2],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"wizard\",\"steps\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"wizard/stepper/item\",[],[[\"@step\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/wizard/stepper.hbs"
    }
  });

  _exports.default = _default;
});