define("@fantasy-account/webapp/templates/web/terms-and-conditions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VbS8FZ5Y",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"web-page\"],[12],[2,\"\\n  \"],[8,\"bold-heading\",[[24,0,\"web-page__title\"]],[[\"@tag\",\"@title\"],[\"h1\",[30,[36,0],[\"terms-and-conditions.title\"],null]]],null],[2,\"\\n\\n  \"],[1,[30,[36,0],[\"terms-and-conditions.content\"],[[\"htmlSafe\"],[true]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/web/terms-and-conditions.hbs"
    }
  });

  _exports.default = _default;
});