define("@fantasy-account/webapp/adapters/ott/f1/session", ["exports", "@fantasy-account/webapp/adapters/ott/application", "@playon/framework/ott/services/f1/adapters/session"], function (_exports, _application, _session) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @public
   *
   * @class OttF1SessionAdapter
   * @extends OttAdapter
   * @mixes OttF1SessionAdapterMixin
   *
   * @since 1.0.0
   */
  var _default = _application.default.extend(_session.default, {});

  _exports.default = _default;
});