define("@fantasy-account/webapp/adapters/ott/application", ["exports", "active-model-adapter", "@playon/framework/ott", "@playon/framework/data", "ember-fetch/mixins/adapter-fetch"], function (_exports, _activeModelAdapter, _ott, _data, _adapterFetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @public
   *
   * @class OttAdapter
   * @extends ActiveModelAdapter
   * @mixes AdapterFetch
   * @mixes OttAdapterAbstract
   *
   * @since 1.0.0
   */
  var _default = _activeModelAdapter.default.extend(_adapterFetch.default, _data.HasHeadersAdapter, _ott.OttAdapterAbstract, _data.AuthenticableAdapter, {});

  _exports.default = _default;
});