define("@fantasy-account/webapp/uploading/index", ["exports", "@fantasy-account/webapp/uploading/uploader"], function (_exports, _uploader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "Uploader", {
    enumerable: true,
    get: function () {
      return _uploader.default;
    }
  });
});