define("@fantasy-account/webapp/templates/components/payment-method/neteller/picker-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XZ3ByclF",
    "block": "{\"symbols\":[\"@className\"],\"statements\":[[8,\"logo\",[[16,0,[31,[[32,1],\"__logo\"]]]],[[\"@kind\"],[\"neteller\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/payment-method/neteller/picker-item.hbs"
    }
  });

  _exports.default = _default;
});