define("@fantasy-account/webapp/components/account/change-password", ["exports", "@fantasy-account/engine/components/account/change-password"], function (_exports, _changePassword) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _changePassword.default;
    }
  });
});