define("@fantasy-account/webapp/templates/components/listing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Pzi9Gb5z",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"Item\",\"Row\",\"item\"],[[30,[36,0],[\"listing-row\"],null],[30,[36,0],[\"listing-row\"],null],[30,[36,0],[\"listing-row\"],null]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/listing.hbs"
    }
  });

  _exports.default = _default;
});