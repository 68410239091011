define("@fantasy-account/webapp/validation/rules/after-or-equal", ["exports", "@playon/framework/validation/rules/after-or-equal"], function (_exports, _afterOrEqual) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _afterOrEqual.default;
    }
  });
});