define("@fantasy-account/webapp/components/modal/aside", ["exports", "@playon/framework/components/modal/aside"], function (_exports, _aside) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _aside.default;
    }
  });
});