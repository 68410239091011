define("@fantasy-account/webapp/templates/components/fantasy-account/wizard/stepper/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bgO8ScjB",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"li\"],[16,0,[32,0,[\"classes\"]]],[17,1],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"step\",\"label\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[[32,0,[\"className\"]],\"__label\"]]],[12],[1,[32,0,[\"step\",\"label\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[[32,0,[\"className\"]],\"__step\"]]],[12],[2,\"\\n    \"],[10,\"span\"],[15,0,[31,[[32,0,[\"className\"]],\"__step__track \",[32,0,[\"className\"]],\"__step__track--left\"]]],[12],[13],[2,\"\\n\\n    \"],[11,\"span\"],[16,0,[31,[[32,0,[\"className\"]],\"__step__number\"]]],[4,[38,1],[\"click\",[32,0,[\"click\"]]],null],[12],[2,\"\\n      \"],[1,[32,0,[\"step\",\"visibleNumber\"]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"span\"],[15,0,[31,[[32,0,[\"className\"]],\"__step__track \",[32,0,[\"className\"]],\"__step__track--right\"]]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"on\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/fantasy-account/wizard/stepper/item.hbs"
    }
  });

  _exports.default = _default;
});