define("@fantasy-account/webapp/templates/components/sorter-sheet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GGL8WoXt",
    "block": "{\"symbols\":[\"menu\",\"sorter\"],\"statements\":[[6,[37,10],[[35,9]],[[\"title\",\"close-button\",\"menu\",\"modifier\"],[[35,8],[35,7],true,\"bordered dashed inseted\"]],[[\"default\"],[{\"statements\":[[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[32,2,[\"hidden\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,2],[[30,[36,1],[[32,1,[\"item\"]],\"expected `menu.item` to be a contextual component but found a string. Did you mean `(component menu.item)`? ('@fantasy-account/webapp/templates/components/sorter-sheet.hbs' @ L4:C8) \"],null],[32,2,[\"label\"]],[32,2,[\"id\"]]],[[\"icon\",\"action\"],[[32,2,[\"icon\"]],[30,[36,0],[[32,0],\"sorterSelected\",[32,2]],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"-assert-implicit-component-helper-argument\",\"component\",\"unless\",\"sorters\",\"-track-array\",\"each\",\"closeButton\",\"title\",\"name\",\"bottom-sheet\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/sorter-sheet.hbs"
    }
  });

  _exports.default = _default;
});