define("@fantasy-account/webapp/templates/components/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YN2KsY4T",
    "block": "{\"symbols\":[\"@registerScrollable\"],\"statements\":[[10,\"main\"],[14,0,\"app\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[[32,0,[\"component\"]]],[[\"registerScrollable\"],[[32,1]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/application.hbs"
    }
  });

  _exports.default = _default;
});