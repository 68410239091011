define("@fantasy-account/webapp/templates/components/language-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MXE3UHhb",
    "block": "{\"symbols\":[\"Control\",\"language\"],\"statements\":[[10,\"div\"],[14,0,\"language__selector\"],[12],[2,\"\\n\\t\"],[8,\"select-control\",[[24,0,\"language__selector__menu\"]],[[\"@label\",\"@selected\",\"@placement\",\"@onChange\"],[[30,[36,0],[[32,0,[\"labelKey\"]]],null],[32,0,[\"selectedLanguage\"]],\"top-start\",[32,0,[\"changeLanguage\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[10,\"div\"],[14,0,\"language__selector__menu__title\"],[12],[1,[30,[36,0],[[32,0,[\"titleKey\"]]],null]],[13],[2,\"\\n\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"application\",\"languages\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t  \\t\"],[10,\"div\"],[14,0,\"language__selector__menu__row\"],[12],[2,\"\\n\\t\\t  \\t\"],[8,\"flag\",[],[[\"@country\",\"@modifier\"],[[32,2,[\"alpha2\"]],\"sm rounded unbordered\"]],null],[2,\"\\n\\t\\t    \"],[8,[32,1,[\"Option\"]],[],[[\"@label\",\"@value\"],[[30,[36,0],[[32,2,[\"labelKey\"]]],null],[32,2,[\"id\"]]]],null],[2,\"\\n\\t  \\t\"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\t\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/language-selector.hbs"
    }
  });

  _exports.default = _default;
});