define("@fantasy-account/webapp/templates/components/avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bcs73zDj",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"img\"],[14,0,\"avatar__image\"],[15,\"alt\",[34,2]],[15,\"src\",[34,3]],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[35,0]],[[\"class\"],[\"avatar__placeholder\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"placeholder\",\"icon-view\",\"alt\",\"src\",\"if\"]}",
    "meta": {
      "moduleName": "@fantasy-account/webapp/templates/components/avatar.hbs"
    }
  });

  _exports.default = _default;
});